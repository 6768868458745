import {db} from "../../../firebase";
import { collection, addDoc, doc, deleteDoc, updateDoc } from "firebase/firestore"

const colecao = "registroscompraevenda";

const db2 = collection(db, colecao);

const pegaTudo = () => {
  return db2;
};

const criaRegistro = (regtemp) => {
  return addDoc(db2, regtemp);
};

const atualiza = (id, value) => {
  return updateDoc(doc(db, colecao), value);
};

const remove = (id) => {
  return deleteDoc(doc(db, colecao, id));
};


const DBService2 = {
  pegaTudo,
  criaRegistro,
  atualiza,
  remove,
};

export default DBService2;