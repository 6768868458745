import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import Paper from "@material-ui/core/Paper";
import DBService from "./dbservice";
import Registro from "./registro";

const ListadeResgistros = () => {
  const [registroAtual, setRegistroAtual] = useState(null);
  const [indiceAtual, setIndiceAtual] = useState(-1);
  const [registros, loading, error] = useCollection(DBService.pegaTudo());

  const refreshList = () => {
    setRegistroAtual(null);
    setIndiceAtual(-1);
  };

  const setRegistroAtivo = (registro, index) => {
    const { criado, assunto, intencao, resposta, tipo, categoria, pergunta, codigo } = registro.data();

    setRegistroAtual({
      id: registro.id,
      criado,
      assunto,
      intencao,
      resposta,
      tipo,
      categoria,
      pergunta,
      codigo
    });

    setIndiceAtual(index);
  };
  return (
    <div className="list row">
      <div className="col-md-5">
        {error && <strong>Erro: {error}</strong>}
        {loading && <span>Carregando...</span>}
        <ul className="list-group">
          <Paper
            style={{
              maxHeight: 300,
              overflow: "auto",
              backgroundColor: "#1e2129",
              color: "#98a0b4",
            }}
          >
            {!loading &&
              registros &&
              registros.docs.map((registro, index) => (
                <li
                  className={
                    "list-group-item " + (index === indiceAtual ? "active" : "")
                  }
                  onClick={() => setRegistroAtivo(registro, index)}
                  key={registro.id}
                >
                  {registro.data().assunto
                  }
                </li>
              ))}
          </Paper>
        </ul>
      </div>

      <div className="col-md-7">
        {registroAtual ? (
          <Registro registro={registroAtual} refreshList={refreshList} />
        ) : (
          <div>
            <br />
            <p>Clique no registro...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListadeResgistros;