import React, { useState } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import DBService from "./dbservice";

const Registro = (props) => {
  const estadoInicial = {
    key: null,
    uid: "",
    criado: "",
    apagar: "",
    assunto: "",
    intencao: "",
    resposta: "",
    pergunta: "",
    tipo: "",
    categoria: "",

  };

  const [regtemp, setRegtemp] = useState(estadoInicial);
  const [mensagem, setMensagem] = useState("");
  const { registro } = props;

  if (regtemp.id !== registro.id) {
    setRegtemp(registro);
    setMensagem("");
  }

  const alteracaoManual = (event) => {
    const { name, value } = event.target;
    setRegtemp({ ...regtemp, [name]: value });
  };

  const atualizaRegistro = () => {
    const data = {
      criado: regtemp.criado,
      assunto: regtemp.assunto,
      intencao: regtemp.intencao,
      resposta: regtemp.resposta,
      pergunta: regtemp.pergunta,
      tipo: regtemp.tipo,
      categoria: regtemp.categoria,
      codigo: regtemp.codigo
    };
    DBService.atualiza(regtemp.id, data)
      .then(() => {
        setMensagem("Registro atualizado com exito!");
        props.refreshList();
        handleClick();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deletaRegistro = () => {
    try {
      if (window.confirm("Você vai mesmo deletar isso?")) {
        DBService.remove(regtemp.id)
      .then(() => {
        props.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

 
  return (
    <div>
      {regtemp ? (
        <div className="submit-form">
          <form>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Intencao</InputGroup.Text>
                <FormControl
                  as="textarea"
                  placeholder="Descreva a intenção que vai aparecer para usúario ler."
                  style={{ height: '100px' }}
                  id="intencao"
                  required
                  value={regtemp.intencao}
                  onChange={alteracaoManual}
                  name="intencao" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Pergunta</InputGroup.Text>
                <FormControl
                  type="text"
                  id="pergunta"
                  required
                  value={regtemp.pergunta}
                  onChange={alteracaoManual}
                  name="pergunta" disabled/>
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Resposta</InputGroup.Text>
                <FormControl
                  as="textarea"
                  placeholder="Escreva a resposta pré configurada que vai aparecer para o usuário editar."
                  style={{ height: '100px' }}
                  id="resposta"
                  required
                  value={regtemp.resposta}
                  onChange={alteracaoManual}
                  name="resposta" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Tipo</InputGroup.Text>
                <select class="form-select"
                  type="text"

                  id="tipo"
                  required
                  value={regtemp.tipo}
                  onChange={alteracaoManual}
                  name="tipo" >
                <option selected></option>
                <option value="pré">Pré - gerado automaticamente</option>
                </select>
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Categoria</InputGroup.Text>
                <FormControl
                  type="text"
                  id="categoria"
                  required
                  value={regtemp.categoria}
                  onChange={alteracaoManual}
                  name="categoria" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Código</InputGroup.Text>
                <FormControl
                  type="text"
                  id="codigo"
                  required
                  value={regtemp.codigo}
                  onChange={alteracaoManual}
                  name="codigo" disabled />
              </InputGroup>
            </div>
          </form>

          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={deletaRegistro}
          >
            Deletar
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={atualizaRegistro}
          >
            Atualizar
          </button>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Registro atualizado com exito!
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div>
          <br />
          <p>Clique no Registro...</p>
        </div>
      )}
    </div>
  );
};


export default Registro;