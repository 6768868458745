import React, { useState } from "react";
import { InputGroup, FormControl } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { SnackbarProvider, useSnackbar } from "notistack";
import DBService from "./dbservice";
import { useAuth  } from "../../../contexts/AuthContext"

const Add = () => {

  const { currentUser } = useAuth();
  
  const estadoInicial = {
    uid: currentUser.uid,
    criado: "",
    apagar: "",
    anotacao: "",
    titulo: "",
    link: "", // Adicionado campo para o link do vídeo
  };

  const gravaRegistro = () => {
    var data = {
      uid: regtemp.uid,
      criado: new Date(),
      apagar: regtemp.apagar,
      titulo: regtemp.titulo,
      link: regtemp.link, // Adicionado campo para o link do vídeo
    };
    DBService.criaRegistro(data)
      .then(() => {
        setEnviado(true);
        outro();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const { enqueueSnackbar } = useSnackbar();

  const outro = () => {
    handleClick("Seus dados foram gravados", "success");
  };

  const handleClick = (dados, variant) => {
    enqueueSnackbar(dados, { variant });
  };

  const [regtemp, setRegtemp] = useState(estadoInicial);
  const [enviado, setEnviado] = useState(false); //altera tela depois de enviar

  const alteracaoManual = event => {
    const { name, value } = event.target;
    setRegtemp({ ...regtemp, [name]: value });
  };

  const novoRegistro = () => {
    setRegtemp(estadoInicial);
    setEnviado(false);
  };

  return (
    <div className="submit-form">
      {enviado ? (
        <div>
          <h4>Seus dados foram gravados!</h4>
          <button className="btn btn-success btn-sm" onClick={novoRegistro}>
            Adicionar
          </button>
        </div>
      ) : (
        <div>
          <Paper style={{ maxHeight: 600, overflow: 'auto', backgroundColor: '#1e2129', color: '#98a0b4' }}>
          <div className="form-group">
              <InputGroup className="mb-3">
                <FormControl
                  type="text"
                  placeholder="Título..."
                  id="titulo"
                  required
                  value={regtemp.titulo}
                  onChange={alteracaoManual}
                  name="titulo" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <FormControl
                  type="text"
                  placeholder="Link do vídeo do YouTube..."
                  id="link"
                  required
                  value={regtemp.link}
                  onChange={alteracaoManual}
                  name="link" />
              </InputGroup>
            </div>
          </Paper>
          <button onClick={gravaRegistro} className="btn btn-success btn-sm">
            Enviar
          </button>
        </div>
      )}
    </div>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Add />
    </SnackbarProvider>
  );
}
