import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import cyborgImage from "./Atendente.png";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function ModalGenerico(informacao) {

  const classes = useStyles();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  informacao = informacao.informacao
  return (
    <>
      <Tooltip title="Tire suas dúvidas aqui!">
        <Button className="btn btn-danger btn-sm" onClick={handleShow} >
          <i class="fa fa-question" aria-hidden="true"></i>
        </Button>
      </Tooltip>
      <Modal className="special_modal" show={show} onHide={handleClose}>
        <Modal.Header className="modal-header-atendente">
          <Modal.Title className="modal-title">
            <div className="avatar-container">
              <Avatar src={cyborgImage} alt="Ariel" className={classes.avatar} />
              <div>
                <div className="text-right text-white font-stylish">Ariel - Atendente Virtual</div>
                <div className="text-right text-white font-stylish">Online</div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {informacao}
        </Modal.Body>
        <Modal.Footer>
          <Tooltip title="Fechar">
            <Button className="btn-secondary btn-sm" onClick={handleClose}>
              <i class="fa fa-times" aria-hidden="true"></i>
            </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
