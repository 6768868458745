import {db} from "../../../firebase";
import { collection, addDoc, doc, deleteDoc, updateDoc, setDoc } from "firebase/firestore"

const colecao = "serviceStatus";

const db2 = collection(db, colecao);

const pegaTudo = () => {
  return db2;
};

const criaRegistro = (data) => {
  return addDoc(db2, data);
};

const criaRegistro2 = (data, name) => {
  return setDoc(doc(db, colecao, name), data);
};

const atualiza = (id, value) => {
  return updateDoc(doc(db, colecao, id), value);
};

const remove = (id) => {
  return deleteDoc(doc(db, colecao, id));
};


const DBService = {
  pegaTudo,
  criaRegistro,
  criaRegistro2,
  atualiza,
  remove,
};

export default DBService;