import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { Input,  message } from 'antd';
import { SnackbarProvider } from 'notistack';
import DBService from './dbservice';

const Modaledit = ({ registro }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [regtemp, setRegtemp] = useState(registro);

  const alteracaoManual = (value, name) => {
    setRegtemp({ ...regtemp, [name]: value });
  };

  const gravaRegistro = () => {
    DBService.atualiza(regtemp.id, regtemp)
      .then(() => {
        outro();
        handleClose();
      })
      .catch((e) => {
      
      });
  };



  const outro = () => {
    handleClick('Seus dados foram atualizados', 'success');
  };

  const handleClick = (dados, variant) => {
    message[variant](dados);
  };

  return (
    <>
      <Tooltip title="Editar">
        <Button onClick={handleShow} className="btn btn-primary btn-sm">
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </Button>
      </Tooltip>
      <Modal
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Editar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="submit-form">
            <div>
              <Input
                addonBefore="Nome"
                type="text"
                id="text"
                required
                value={regtemp.text}
                onChange={(e) => alteracaoManual(e.target.value, 'text')}
              />
            </div>
            <div className="form-group">
              <Form.Control
                as="select"
                value={regtemp.botoes}
                onChange={(e) => {
                  alteracaoManual(e.target.value, 'botoes');
                }}
                custom
              >
                <option value="1">Liga/Desliga</option>
                <option value="0">Pulso</option>
              </Form.Control>
            </div>
            <div className="form-group">
              <Input
                addonBefore="ID"
                type="text"
                id="moduleId"
                required
                value={regtemp.moduleId}
                onChange={(e) => alteracaoManual(e.target.value, 'moduleId')}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button onClick={handleClose} >
            Fechar
          </Button>
          <Button onClick={gravaRegistro} type="primary" className="btn-success">
            Atualizar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default function IntegrationNotistack({ registro }) {
  return (
    <SnackbarProvider maxSnack={3}>
      <Modaledit registro={registro} />
    </SnackbarProvider>
  );
}
