import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import MensagemEmMassa from "./envioMassa"
import Tooltip from "@material-ui/core/Tooltip";

export default function EnvioMassaModal(registro) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Tooltip title="Envio em Massa">
        <Button className="btn btn-primary btn-sm" onClick={handleShow} >
          <i class="fa fa-bullseye" aria-hidden="true"></i>
        </Button>
      </Tooltip>
      <Modal className="special_modal" size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Envio em massa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MensagemEmMassa id={registro.id} />
        </Modal.Body>
        <Modal.Footer>
        <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
