import React from "react";
import { Card, Row } from 'antd';
import AddModal from "../Modulos/modalAdd";
import Modulos from "../Cards/cards";

const Painel = (currentUser) => {
    return (
        <div>
            <Card title={
                <Row justify="space-between" align="middle">
                    Módulos Automação
                    <AddModal usuario={currentUser} />
                </Row>
            }>
                <div className="list row">
                    <ul className="list-group">
                        <div className="center">
                            <Modulos />
                        </div>
                    </ul>
                </div>
            </Card>
        </div>
    );
};



export default Painel;
