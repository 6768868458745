import React, { useState } from 'react';

const FormatarNumeros = () => {
  const [inputNumeros, setInputNumeros] = useState('');
  const [numerosFormatados, setNumerosFormatados] = useState('');

  const formatarNumeros = () => {
    const numerosArray = inputNumeros.split(',');

    const numerosFormatadosArray = numerosArray.map((numero) => {
      const numeroSemCodigoPais = numero.slice(2);
      const numeroFormatado = `(${numeroSemCodigoPais.slice(0, 2)}) ${numeroSemCodigoPais.slice(2, 7)}-${numeroSemCodigoPais.slice(7)}`;
      return numeroFormatado;
    });

    setNumerosFormatados(numerosFormatadosArray.join(', '));
  };

  return (
    <div>
      <h2>Insira os números separados por vírgula:</h2>
      <textarea
        value={inputNumeros}
        onChange={(e) => setInputNumeros(e.target.value)}
        rows="4"
        cols="50"
      />

      <br />

      <button onClick={formatarNumeros}>Formatar Números</button>

      <h2>Números Formatados:</h2>
      <p>{numerosFormatados}</p>
    </div>
  );
};

export default FormatarNumeros;
