import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import Paper from "@material-ui/core/Paper";
import Registro from "./editarregistro";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { db } from "../../../firebase";
import { collection, where, query } from "firebase/firestore"
const colecao = "todo";

const ListadeResgistros = (currentUser) => {
  const [registroAtual, setRegistroAtual] = useState(null);
  const [indiceAtual, setIndiceAtual] = useState(-1);
  const [registros, loading, error] = useCollection(query(collection(db, colecao), where("uid", "==", currentUser.usuario.usuario.usuario.uid)));


  const refreshList = () => {
    setRegistroAtual(null);
    setIndiceAtual(-1);
  };

  const setRegistroAtivo = (registro, index) => {
    const { uid, criado, apagar, nome, descricao, estado } = registro.data();

    setRegistroAtual({
      id: registro.id,
      uid,
      criado,
      apagar,
      nome,
      descricao,
      estado
    });

    setIndiceAtual(index);
  };
  return (
    <Tabs
      defaultActiveKey="Concluir"
      id="uncontrolled-tab-example"
      className="mb-3"
      variant="dark"
      onClick={refreshList}
      fill
    >
      <Tab eventKey="Concluir" title="Tarefas" >
        <div className="list row">
          <div className="col-md-5">
            {error && <strong>Erro: {error}</strong>}
            {loading && <span>Carregando...</span>}
            <ul className="list-group">
              <Paper
                style={{
                  maxHeight: 300,
                  overflow: "auto",
                  backgroundColor: "#1e2129",
                  color: "#98a0b4",
                }}
              >
                {!loading &&
                  registros &&
                  registros.docs.map((registro, index) => (
                    <i>
                      <div>{registro.data().estado !== "concluído" ? <div>
                        <li
                          className={
                            "list-group-item " + (index === indiceAtual ? "active" : "")
                          }
                          onClick={() => setRegistroAtivo(registro, index)}
                          key={registro.id}
                        >
                          {registro.data().nome}
                        </li> </div> : <div></div>} </div>
                    </i>
                  ))}
              </Paper>
            </ul>
          </div>

          <div className="col-md-7">
            {registroAtual ? (
              <Registro registro={registroAtual} refreshList={refreshList} />
            ) : (
              <div>
                <br />
                <p>Clique no registro...</p>
              </div>
            )}
          </div>

        </div>
      </Tab>
      <Tab eventKey="Concluidas" title="Concluidas" >
        <div className="list row">
          <div className="col-md-5">
            {error && <strong>Erro: {error}</strong>}
            {loading && <span>Carregando...</span>}
            <ul className="list-group">
              <Paper
                style={{
                  maxHeight: 300,
                  overflow: "auto",
                  backgroundColor: "#1e2129",
                  color: "#98a0b4",
                }}
              >
                {!loading &&
                  registros &&
                  registros.docs.map((registro, index) => (
                    <i>
                      <div>{registro.data().estado === "concluído" ? <div>
                        <li
                          className={
                            "list-group-item " + (index === indiceAtual ? "active" : "")
                          }
                          onClick={() => setRegistroAtivo(registro, index)}
                          key={registro.id}
                        >
                          {registro.data().nome}
                        </li> </div> : <div></div>} </div>
                    </i>
                  ))}
              </Paper>
            </ul>
          </div>

          <div className="col-md-7">
            {registroAtual ? (
              <Registro registro={registroAtual} refreshList={refreshList} />
            ) : (
              <div>
                <br />
                <p>Clique no registro...</p>
              </div>
            )}
          </div>

        </div>
      </Tab>
    </Tabs>
  );
};

export default ListadeResgistros;