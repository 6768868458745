import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Modal } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Copyright from "./Copyright";
import Erro from "./Funcoes/Erro/erro"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const nomeRef = useRef();
  const telefoneRef = useRef();
  const [phone, setPhone] = useState("");

  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  var res = phone.replace(" ", "")
  var res2 = res.replace("-", "")
  var res3 = res2.replace("(", "")
  var telefone= res3.replace(")", "")

  const maskPhone = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})(\d+?)$/, "$1");
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("As senhas não coincidem");
    }

    try {
      setError("");
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value, nomeRef.current.value, telefone);
      history.push("/");
    } catch {
      setError("Falha ao criar uma conta");
      Erro("Falha ao criar uma conta")
    }

    setLoading(false);
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar
          className={classes.avatar}
          src={"image/logo.png"}
        />
        <br />
      </div>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Inscrever-se</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="nome">
              <Form.Label>Nome completo</Form.Label>
              <Form.Control type="name" ref={nomeRef} placeholder="Informe seu nome completo" required />
            </Form.Group>
            <Form.Group id="telefone">
              <Form.Label>Telefone</Form.Label>
              <Form.Control type="telefone" value={phone} onChange={(e) => setPhone(maskPhone(e.target.value))} ref={telefoneRef} placeholder="Informe seu telefone" required />
            </Form.Group>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} placeholder="Informe seu e-mail" required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Senha</Form.Label>
              <Form.Control type="password" ref={passwordRef} placeholder="Senha acima de 6 caracteres" required />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Confirmar Senha</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} placeholder="Repita a mesma senha" required />
            </Form.Group>
            <br></br>
            <div>
              <Form.Check
                required
                label={'Concordo com os termos e condições.'}
                feedback="Você deve concordar antes de enviar."
                feedbackType="invalid"
              />
            </div>
            <br></br>
            <Button disabled={loading} className="w-100" type="submit">
              Inscrever-se
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Já tem uma conta? <Link to="/login">Conecte-se</Link>
      </div>

      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>
  );
}

function Termos() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div>
        <button onClick={handleShow}>asdf</button>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Termos e Condições</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
