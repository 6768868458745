import { db } from "../../../firebase";
import { collection, addDoc, doc, deleteDoc, updateDoc } from "firebase/firestore"
import axios from 'axios';

const colecao = "lancamentosincronizado";

const db2 = collection(db, colecao);

const pegaTudo = () => {
  return db2;
};

const criaRegistro = (data) => {
 const config = {
    method: 'post',
    url: 'https://agteletronic.com.br/sincronizado', //na hospedagem não está aceitando localhost
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  axios(config)
    .then(function (response) {
    })
    .catch(function (error) {
      alert('Sua intenção não foi criada.');
    });
    
};

const atualiza = (id, value) => {
  return updateDoc(doc(db, colecao, id), value);
};

const remove = (id) => {
  return deleteDoc(doc(db, colecao, id));
};


const DBService = {
  pegaTudo,
  criaRegistro,
  atualiza,
  remove,
};

export default DBService;