import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { onSnapshot, doc } from "firebase/firestore"
import {db} from "../../../firebase";

export default function QR(id) {

    const [registros, setRegistros] = useState({qrcode: "2@XbGD6ulK05PUGh5MluMuEJsMX83DTRq1ChCxiZ1ce4d45SqhlEs7KrR2265A==,Y0BFs8V5fJjkR5xruHfEa+z5DSaz/zPClpPjwpTF1mw=,f8aEt8JYXk+BA0QJyjvn/tIgpuft/KqC74k1xeB8BWI=,CGOEVoDW34BFM52Kz1V+OnYL5EWo20A1imbw36o4IOd6/sUY6ZE2EEhqM371Y="})
  useEffect(() => {
    const unsub = onSnapshot(doc(db, "qr", id.id.id), (doc) => {
      setRegistros(
        { ...doc.data(), id: doc.id }
      );
  });
    return unsub;
  }, []);

    return (
        <div className="container">
                    <div className="panel-body">
                      <p><strong>Abra seu aplicativo do WhatsApp e aponte a camera para o Qr Code.</strong></p>
                      <QRCode value={registros.qrcode} />
                      <p><br/><strong>Ao autenticar esta sessão estará habilitada para enviar mensagens.</strong></p>
                    </div>
        </div>
    );
}
                    
