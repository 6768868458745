import React from "react";
import { Card, Row } from 'antd';

import { ListGroup } from 'react-bootstrap';

// Card Serviços
import SessoesModal from "./Sessoes/Modal";
import SessoesRoboModal from "./Sessoes_Robo/Modal";
import ModalAtendimento from "./Atendimento/ModalAtendimento";
import TabelaModalPedidos from "./Pedidos/modaltabela";
// Serviços
import ListadeResgistrosTabela from "../Gerenciamento/ServiceStatus/tabela";
// Card Suporte
import TodoModal from "../Suporte/Todo/modal";
import AnotacoesModal from "../Suporte/Anotacoes/modal";
import LinkModal from "../Suporte/Links/modal";
import TutoriaisModal from "../Suporte/Backup/modal";
import MicroservicosModal from "../Suporte/Microservicos/modal";
// Card Gerenciamento
import PerSinModal from "../Gerenciamento/lancamentosincronizado/modal";
import PrecoModal from "../Gerenciamento/Preco/modal";
import EditorTabelaFaturaModal from "../Gerenciamento/Editorfaturas/modaltabela";
import UsuariosTabelaModal from "../Gerenciamento/EditorUsuarios/modaltabela";
import SessaoTabelaModal from "../Gerenciamento/EditaSessao/modaltabela";
import StatusTabelaModal from "../Gerenciamento/ServiceStatus/modaltabela"
import VideoTutorialModal from "../Gerenciamento/VideoTutorial/modal";
import ScriptModal from "../Suporte/Script/modal";
// Card Finanças
import ListadeResgistrosTabelaFinancas from '../Financas/Cotacoes/tabela';
// Card Utilitários
import RetiraModal from "../Utilitarios/RetiraPais/Modal";
import SubPalavrasModal from "../Utilitarios/SubPalavras/modal";
import RegistroModal2 from "../Utilitarios/GeradorDePrompt/modal";
import GeradordeConteudoModal from "../Utilitarios/GeradordeConteudo/modal";
import TesteChatModal from "../Utilitarios/TestesChat/modal";
import ChatSimples from "../Utilitarios/ChatSimples/modal";
import UploadModal from "../Utilitarios/FileUpload/Modal";

// Card Automação
import Painel from "../AutomacaoTotal/Painel/index"

const Listas = (currentUser) => {
  return (
    <div>
      <Painel usuario={currentUser} />
      <p></p>
      {currentUser.usuario.uid === "tgwbiQOizQQwcyppT0YXmljuiev2" ?
        <div>
          <Card title="Desenvolvimento">
            <div className="list row">
              <ul className="list-group">
                <div className="center">
                  <Row>
                  <SessoesModal usuario={currentUser} />
                    <SessoesRoboModal usuario={currentUser} />
                    <TabelaModalPedidos usuario={currentUser}/>
                  </Row>
                </div>
              </ul>
            </div>
          </Card>
          <br />
           <Card title="Estado dos Serviços">
            <div className="list row">
              <ul className="list-group">
                <div className="center">
                  <Row>
                  <ListadeResgistrosTabela usuario={currentUser}/>
                  </Row>
                </div>
              </ul>
            </div>
          </Card>
          <br />
          <Card title="Gerênciamento">
            <div className="list row">
              <ul className="list-group">
                <div className="center">
                  <Row>
                  <VideoTutorialModal usuario={currentUser}/>
                    <UsuariosTabelaModal usuario={currentUser} />
                    <SessaoTabelaModal usuario={currentUser} />
                    <EditorTabelaFaturaModal usuario={currentUser} />
                    <PrecoModal usuario={currentUser} />
                    <PerSinModal />
                    <StatusTabelaModal usuario={currentUser}/>
                    <ScriptModal usuario={currentUser}/>
                    <ModalAtendimento usuario={currentUser}/>
                  </Row>
                </div>
              </ul>
            </div>
          </Card>
          <br />
          <Card title="Suporte">
            <div className="list row">
              <ul className="list-group">
                <div className="center">
                  <Row>
                    <TodoModal usuario={currentUser} />
                    <AnotacoesModal usuario={currentUser} />
                    <LinkModal usuario={currentUser} />
                    <TutoriaisModal usuario={currentUser} />
                    <MicroservicosModal usuario={currentUser} />
                  </Row>
                </div>
              </ul>
            </div>
          </Card>
          <br />
          <Card title="Utilitários">
            <div className="list row">
              <ul className="list-group">
                <div className="center">
                  <Row>
                  <ListGroup style={{ width: '300px' }}>
                  <SubPalavrasModal/>
                  <RegistroModal2/>
                  <GeradordeConteudoModal/>
                  <TesteChatModal usuario={currentUser} />
                  <ChatSimples usuario={currentUser} />
                  <RetiraModal/>
                  <UploadModal/>
                  </ListGroup>
                  </Row>
                </div>
              </ul>
            </div>
          </Card>
          <br />
          <Card title="Financeiro">
                  <ListadeResgistrosTabelaFinancas usuario={currentUser} />
          </Card>
          <br /></div> : <div></div>}
    </div>
  );

};



export default Listas;
