import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import QR from "./index";

export default function QrModal(id) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  return (
    <>
      <Button className="btn btn-primary btn-sm" onClick={handleShow} >
            Qr Code
          </Button>
      <Modal className="modal" size="sm" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Autenticar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <QR id = {id}/>
        </Modal.Body>
      </Modal>
    </>
  );
}
