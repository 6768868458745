import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { ListGroup } from 'react-bootstrap';
import GeradordeConteudo from './GeradordeConteudo';
const Nome = "Gerador de Conteúdo"

export default function GeradordeConteudoModal(currentUser) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
        <ListGroup.Item onClick={handleShow}>{Nome}</ListGroup.Item>
      <Modal className="special_modal" size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {Nome}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GeradordeConteudo/>
        </Modal.Body>
        <Modal.Footer>
          <Tooltip title="Fechar">
            <Button className="btn-secondary btn-sm" onClick={handleClose}>
              <i class="fa fa-times" aria-hidden="true"></i>
            </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
