// Modulos.js
import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { Button, Modal } from 'react-bootstrap';
import { WifiOutlined } from '@ant-design/icons';
import Comandos from '../Cards/comandos';
import AutomacaoTabelaModal from "../horaprogramada/modaltabela";
import { onSnapshot, collection } from 'firebase/firestore';
import { db } from './firestoreConfig';
import Modaledit from '../Modulos/modalEdit';
import DBService from '../Modulos/dbservice';
import Tooltip from "@material-ui/core/Tooltip";

const { Meta } = Card;

const Modulos = () => {
    const [cardsList, setCardsList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCardIndex, setSelectedCardIndex] = useState(null);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'Modulos'), (querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setCardsList(data);

            // Verifica se a lista está vazia e executa a função se for o caso
            if (data.length === 0) {
                setIsModalVisible(false);
                setSelectedCardIndex(null);
            }
        });

        // Isso cancelará a inscrição no ouvinte de snapshot quando o componente for desmontado
        return () => unsubscribe();
    }, []);

    const showModal = (index = null) => {
        setSelectedCardIndex(index);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (selectedCardIndex !== null) {
            // Handle update logic if needed
        } else {

        }
        setIsModalVisible(false);
        setSelectedCardIndex(null);
    };

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const showConfirmExcluirModulo = (index) => {
        setSelectedCardIndex(index);
        setShowConfirmModal(true);
    };

    const hideConfirmExcluirModulo = () => {
        setShowConfirmModal(false);
    };

    const confirmExcluirModulo = () => {
        // Realiza a exclusão do módulo
        hideConfirmExcluirModulo();
        setIsModalVisible(false);
        setSelectedCardIndex(null);

        DBService.remove(cardsList[selectedCardIndex].id);


    };


    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedCardIndex(null);
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px' }}>
            <Modal show={isModalVisible} onHide={handleCancel} size="sm" centered>
                <Modal.Header className="custom-modal-header">
                    <Modal.Title className="custom-modal-title">
                        {selectedCardIndex !== null ? `Módulo - ${cardsList[selectedCardIndex].text}` : 'Módulo'}
                    </Modal.Title>
                    <Tooltip title="Excluir">
                    <Button variant="danger" onClick={() => showConfirmExcluirModulo(selectedCardIndex)}>
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </Button>
                        </Tooltip>
                </Modal.Header>
                <Modal.Body>
                    {selectedCardIndex !== null ? (

                        <Comandos
                            texto={cardsList[selectedCardIndex].text}
                            topic={cardsList[selectedCardIndex].moduleId}
                            botoes={cardsList[selectedCardIndex].botoes}
                        />
                    ) : (
                        <div>

                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="custom-modal-footer">

                    <Modaledit registro={cardsList[selectedCardIndex]} />
                        <AutomacaoTabelaModal registro={cardsList[selectedCardIndex]}/>
                        <Button variant="primary btn-sm" className="mr-2" onClick={handleOk}>
                            OK
                        </Button>

                </Modal.Footer>
            </Modal>
            {/* Modal de confirmação */}
            <Modal show={showConfirmModal} onHide={hideConfirmExcluirModulo} size="sm" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Tem certeza que deseja excluir este módulo?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideConfirmExcluirModulo}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmExcluirModulo}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', marginTop: '20px' }}>
                {cardsList.map((card, index) => (
                    <Card key={index} onClick={() => showModal(index)} hoverable style={{ width: 110, margin: '2px' }}>
                        <Meta title={card.text} style={{ fontSize: '10px' }} />
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <WifiOutlined style={{ fontSize: '40px', color: '#2eb2ff' }} />
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default Modulos;
