import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase';

function AddStockModal({ show, handleClose }) {

    const [stockData, setStockData] = useState({
        precomedio: '',
        quantidade: '',
        last_price: '0',
        nomeRegistro: '', // Novo campo para o nome do registro
      });

    const addStock = async (newStockData) => {

        try {
          const docRef = await setDoc(doc(db, 'cotacoes', stockData.nomeRegistro), {
            ...newStockData,
          });
          console.log('Document written with ID: ', docRef.id);
        } catch (error) {
          console.error('Error adding document: ', error);
        }
      };

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStockData({
      ...stockData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Get the current date and time
    const currentDate = new Date();
  
    // Format the date and time as "YYYY-MM-DD HH:MM:SS"
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
  
    // Update the stockData object with the lastupdate field
    const updatedStockData = {
      ...stockData,
      last_update: formattedDate, // Add the lastupdate field with the formatted date
    };
  
    // Call the addStock function with the updated data
    addStock(updatedStockData);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Papel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="nomeRegistro">
            <Form.Label>Papel</Form.Label>
            <Form.Control
              type="text"
              name="nomeRegistro"
              onChange={handleChange}
              value={stockData.nomeRegistro}
              required
            />
          </Form.Group>
          <Form.Group controlId="precomedio">
            <Form.Label>Preço Médio</Form.Label>
            <Form.Control
              type="number"
              name="precomedio"
              step="0.01"
              onChange={handleChange}
              value={stockData.precomedio}
              required
            />
          </Form.Group>
          <Form.Group controlId="quantidade">
            <Form.Label>Quantidade</Form.Label>
            <Form.Control
              type="number"
              name="quantidade"
              onChange={handleChange}
              value={stockData.quantidade}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Adicionar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddStockModal;
