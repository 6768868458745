import React, { useEffect, useRef } from "react";
import "./YouTubePlayer.css";

const YouTubePlayer = ({ videoId }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    const playerElement = playerRef.current;
    const { clientWidth, clientHeight } = document.documentElement;

    // Remove o elemento de vídeo anterior se existir
    while (playerElement.firstChild) {
      playerElement.removeChild(playerElement.firstChild);
    }

    // Calcula a largura e altura do vídeo com base nas dimensões da janela do navegador
    const width = Math.min(clientWidth, 560);
    const height = (width / 560) * 315;

    // Cria um novo elemento de vídeo do YouTube com as dimensões ajustadas
    const newPlayerElement = document.createElement("iframe");
    newPlayerElement.src = `https://www.youtube.com/embed/${videoId}`;
    newPlayerElement.width = width;
    newPlayerElement.height = height;
    newPlayerElement.title = "YouTube video player";
    newPlayerElement.frameBorder = "0";
    newPlayerElement.allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
    newPlayerElement.allowFullscreen = true;

    // Adiciona o novo elemento de vídeo ao componente
    playerElement.appendChild(newPlayerElement);

    return () => {
      // Limpa o elemento de vídeo ao desmontar o componente
      while (playerElement.firstChild) {
        playerElement.removeChild(playerElement.firstChild);
      }
    };
  }, [videoId]);

  return <div className="youtube-player" ref={playerRef}></div>;
};

export default YouTubePlayer;
