import React, { useState, useEffect } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import Paper from "@material-ui/core/Paper";
import { Table } from 'react-bootstrap';
import Tooltip from "@material-ui/core/Tooltip";
import 'moment/locale/pt-br';
import { db } from "../../../firebase";
import DBService from "./dbservice";
import Modaledit from "./modalEdit";
import moment from 'moment';
import 'moment/locale/pt-br';


const colecao = "faturas";

const ListadeResgistrosTabela = (currentUser) => {

  const [registros, setRegistros] = useState([])

  useEffect(() => {
    const usersCollectionRef = query(collection(db, colecao));
    const unsub = onSnapshot(usersCollectionRef, (querySnapshot) => {
      setRegistros(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
    return unsub;
  }, []);

  const deletaRegistro = (registro) => {
    if (window.confirm("Você vai mesmo deletar isso?")) {
      DBService.remove(registro)
        .then(() => {
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div>
      {registros.length === 0 ? <div><div class="alert alert-warning" role="alert">
        Ainda não temos nenhum registro cadastrado, clique em "+" para cadastrar.
      </div></div> : <div>
        <div className="card mb-4">
          <div style={{ width: '100%' }}>
            <ul className="list-group"><Paper style={{ maxHeight: 400, overflow: 'auto' }}>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Identificador</th>
                    <th>Estado</th>
                    <th>Id_secao</th>
                    <th>Nomesecao</th>
                    <th>Uid</th>
                    <th>Validade</th>
                    <th>Valor</th>
                    <th>Comandos</th>
                  </tr>
                </thead>
                <tbody>
                  {registros.map((registro) => (
                    <tr>
                      <td>{registro.id}</td>
                      <td>{registro.estado}</td>
                      <td>{registro.id_secao}</td>
                      <td>{registro.nomesecao}</td>
                      <td>{registro.uid}</td>
                      {registro.validade !== "" ?
                        <td>{moment(registro.validade.toDate().toString()).format('L')}</td>
                        : <td></td>}
                      <td>{registro.valor}</td>
                      <td>
                        <Modaledit registro={registro} />
                        { registro.estado === "Aberto" ?
                        <Tooltip title="Deletar"><button onClick={() => deletaRegistro(registro.id)} className="btn btn-danger btn-sm">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                        </Tooltip>
                        : <div></div>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Paper>
            </ul>
          </div>
        </div>
      </div>}
    </div>
  );
}
export default ListadeResgistrosTabela;