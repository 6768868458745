import React, { useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { Button, Modal } from 'react-bootstrap';
import { SnackbarProvider, useSnackbar } from 'notistack';
const API_ENDPOINT = 'https://agteletronic.com.br/sendData';

function Mqtt(registro) {

    const Sessao = registro.registro.id.id.id_secao;
    const { enqueueSnackbar } = useSnackbar();

    const handleApiRequest = async (comando, novoTopico) => {
        try {
            const response = await fetch(API_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ topic: novoTopico, payload: comando }),
            });

            if (response.ok) {
                enqueueSnackbar('Command sent successfully!', { variant: 'success' });
            } else {
                enqueueSnackbar('Failed to send command', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error sending command:', error);
            enqueueSnackbar('Error sending command', { variant: 'error' });
        }
    };




    return (
        <div className="container">

            <form>
                <Alert variant="danger">
                    Atenção!
                    Ao clicar em "Refazer minha sessão" será necessário autenticar novamente.
                </Alert>
                <Button variant="primary" onClick={() => handleApiRequest('{"nome":"' + Sessao + '"}', 'gerador/reset')}>
                    Reset
                </Button>
            </form>
        </div>

    );
}

export default function Rst(registro) {
    return (
        <SnackbarProvider maxSnack={3}>
            <Mqtt registro={registro} />
        </SnackbarProvider>
    );
}