import React, { useContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
  sendPasswordResetEmail
} from 'firebase/auth';
import { auth } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import axios from 'axios';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password, nome, telefone) {
    return createUserWithEmailAndPassword(auth, email, password, nome)
      .then((authenticate) => {
        if (authenticate.user) {
          criarUsuario(nome, authenticate.user.uid, telefone, email)
        }
      });
  }

  function criarUsuario(nome, uid, telefone, email) {
    const dados = {
      number: "819017083222",
      message: "Usuário cadastrado",
      id: process.env.REACT_APP_ID,
      token: process.env.REACT_APP_TOKEN
    };

    const dados2 = {
      number: "55" + telefone,
      message: "*Olá 👋*\r\n\r\nTudo bem, *" + nome + "?*\r\n\r\nSeu cadastro já foi realizado 👍\r\nEsperamos que você aproveite todo o potencial da nossa API, que ela também ajude você a alavancar seu negócio, como já tem ajudado automatizar muitos outros negócios por todo o país. \r\n\r\nE se tiver qualquer dúvida por favor entre em contato conosco por este número de WhatsApp. Teremos o prazer em tirar suas dúvidas.\r\n\r\nLembrando, sua primeria sessão funcionará totalmente de graça por 7 dias.\n\r\nTudo de bom pra você "+ nome +".\r\n\r\n🤝" ,
      id: process.env.REACT_APP_ID,
      token: process.env.REACT_APP_TOKEN
    };

    axios({
      method: 'POST',
      url: 'https://api.apidozap.com.br/send-message',
      data: dados2
    }).then(Confirma())

    function Confirma() {
      axios({
        method: 'POST',
        url: 'https://api.apidozap.com.br/send-message',
        data: dados
      })
    }

    setDoc(doc(db, "usuarios", uid), {
      nome: nome,
      telefone: "55" + telefone,
      endereço: "",
      imagem: "",
      uid: uid,
      criado: "",
      dataevento: "",
      servidor: "",
      estado: "novo",
      email: email
    });
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmails(email) {
    return updateEmail(auth.currentUser, email);
  }

  function updatePasswords(password) {
    return updatePassword(auth.currentUser, password);
  }


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmails,
    updatePasswords,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
