import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from 'axios';
import { useAuth } from "../../../contexts/AuthContext";
import Erro from "../Erro/erro";
import Tooltip from "@material-ui/core/Tooltip";

export default function GerarModal(tipo) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Msg = () => {

    const { currentUser } = useAuth();
    const [regtemp, setRegtemp] = useState({
      nomesecao: "",
    });

    const data = JSON.stringify({
      "id_secao": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      "token": currentUser.uid,
      "nomesecao": regtemp.nomesecao,
      "estado": "",
      "tipo": tipo.tipo,
      "criado": new Date()
    });

    const config = {
      method: 'post',
      url: 'https://agteletronic.com.br/gerarSecao',
      //url: 'http://localhost:4003/gerarSecao', //na hospedagem não está aceitando localhost
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    const alteracaoManual = (event) => {
      const { name, value } = event.target;
      setRegtemp({ ...regtemp, [name]: value });
    };

    const handleFormSubmit = () => {
      if (regtemp.nomesecao == "") {
        alert("O nome da sessão não pode ser vazio")
      } else {
        axios(config)
          .then(function (response) {
            //console.log(JSON.stringify(response.data.message));
            alert('Sua sessão foi criada. Clique no botão "Qr code" para autenticar com WhatsApp e enviar mensagens');
            setShow(false)
          })
          .catch(function (error) {
            alert('Desculpe-nos, mas estamos fazendo uma manutenção temporária em nosso site. Por favor, tente novamente mais tarde. Obrigado pela sua compreensão.');
            Erro('Erro ao gerar sessão no site')
          });
      }
    }

    return (
      <div className="container">
        <label>Nome da Sessão</label>
        <input
          type="text"
          name="nomesecao"
          className="form-control"
          placeholder="crie um nome para sessão"
          value={regtemp.nomesecao}
          onChange={alteracaoManual}
        />
        <br />
        <input
          type="submit"
          className="btn btn-primary btn-block btn-sm"
          onClick={handleFormSubmit}
          value="Criar" />
      </div>
    );
  }
  return (
    <>
      <Button className="btn-primary btn-sm" onClick={handleShow}>
        + Nova sessão
      </Button>
      <Modal className="special_modal" size="sm" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Nova sessão
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Msg />
        </Modal.Body>
        <Modal.Footer>
        <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
