import React, { useState, useEffect } from "react";
import { InputGroup, FormControl } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { SnackbarProvider, useSnackbar } from "notistack";
import DBService from "./dbservice";

const Edit = (registro) => {

  const [regtemp, setRegtemp] = useState(registro.registro.registro.registro);

  const gravaRegistro = () => {
    DBService.atualiza(regtemp.id, regtemp)
      .then(() => {
        outro()
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const { enqueueSnackbar } = useSnackbar();

  const outro = () => {
    handleClick("Seus dados foram atualizados", "success");
  };

  const handleClick = (dados, variant) => {
    enqueueSnackbar(dados, { variant });
  };

  

  const alteracaoManual = event => {
    const { name, value } = event.target;
    setRegtemp({ ...regtemp, [name]: value });
  };


  return (
    <div className="submit-form">
      <div>
        <Paper style={{ maxHeight: 600, overflow: 'auto', backgroundColor: '#1e2129', color: '#98a0b4' }}>
          <InputGroup className="mb-3 d-flex flex-row-reverse" ><i>{new Date(regtemp.criado.seconds * 1000).toLocaleString()}</i></InputGroup>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Nomesecao</InputGroup.Text>
              <FormControl
                type="text"
                id="nomesecao"
                required
                value={regtemp.nomesecao}
                onChange={alteracaoManual}
                name="nomesecao" />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Id_secao</InputGroup.Text>
              <FormControl
                type="text"
                id="id_secao"
                required
                value={regtemp.id_secao}
                onChange={alteracaoManual}
                name="id_secao" />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Token</InputGroup.Text>
              <FormControl
                type="text"
                id="token"
                required
                value={regtemp.token}
                onChange={alteracaoManual}
                name="token" />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Tipo</InputGroup.Text>
                <select class="form-select"
                  type="text"
                  id="tipo"
                  required
                  value={regtemp.tipo}
                  onChange={alteracaoManual}
                  name="tipo" >
                <option value="api">API</option>
                <option value="robo">Robô</option>
                <option value="disparador">Disparador</option>
                </select>
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Estado</InputGroup.Text>
                <select class="form-select"
                  type="text"
                  id="estado"
                  required
                  value={regtemp.estado}
                  onChange={alteracaoManual}
                  name="estado" >
                <option value="Hibernado">Hibernado</option>
                <option value="Autenticado">Autenticado</option>
                </select>
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Pagamento</InputGroup.Text>
                <select class="form-select"
                  type="text"
                  id="pagamento"
                  required
                  value={regtemp.pagamento}
                onChange={alteracaoManual}
                  name="pagamento" >
                <option value="Aguardando pagamento">Aguardando pagamento</option>
                <option value="Pago">Pago</option>
                <option value="Teste">Teste</option>
                </select>
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Porta</InputGroup.Text>
              <FormControl
                type="text"
                id="porta"
                required
                value={regtemp.porta}
                onChange={alteracaoManual}
                name="porta" />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Validade</InputGroup.Text>
              <FormControl
                type="text"
                id="validade"
                required
                value={regtemp.validade}
                onChange={alteracaoManual}
                name="validade" />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Valor</InputGroup.Text>
              <FormControl
                type="text"
                id="valor"
                required
                value={regtemp.valor}
                onChange={alteracaoManual}
                name="valor" />
            </InputGroup>
          </div>
        </Paper>
        <button onClick={gravaRegistro} className="btn btn-success btn-sm">
          Atualizar
        </button>
      </div>
    </div>
  );
};

export default function IntegrationNotistack(registro) {
  return (
    <SnackbarProvider maxSnack={3}>
      <Edit registro={registro} />
    </SnackbarProvider>
  );
}