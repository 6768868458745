import React, { useState, useEffect } from "react";
import { InputGroup, FormControl } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { SnackbarProvider, useSnackbar } from "notistack";
import DBService from "./dbservice";
import DBService2 from "./dbservice2";

const 
Venda = (registro) => {
  const [regtemp, setRegtemp] = useState(registro.registro.registro.registro);
  const [novaQuantidade, setNovaQuantidade] = useState(0); // Estado para a Nova Quantidade
  const [valorVenda, setValorVenda] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Atualize o estado local quando as propriedades 'registro' mudarem
    setRegtemp(registro.registro.registro.registro);
  }, [registro]);

  const Vender = () => {
    // Converter novaQuantidade e regtemp.quantidade para números
    const quantidadeVenda = parseInt(novaQuantidade);
    const quantidadeAtual = parseInt(regtemp.quantidade);
  
    if (!isNaN(quantidadeVenda) && !isNaN(quantidadeAtual)) {
      const newQuantidade = quantidadeAtual - quantidadeVenda;
      setRegtemp({
        ...regtemp,
        quantidade: newQuantidade
      });
      // Atualizar o banco de dados após a atualização local
      gravaRegistro(newQuantidade);
    }
  };
  
  const gravaRegistro = (newQuantidade) => {
    const dataAtual = new Date();

    const ano = dataAtual.getFullYear();
    const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0'); // Os meses são base 0
    const dia = dataAtual.getDate().toString().padStart(2, '0');
    const horas = dataAtual.getHours().toString().padStart(2, '0');
    const minutos = dataAtual.getMinutes().toString().padStart(2, '0');
    const segundos = dataAtual.getSeconds().toString().padStart(2, '0');

    const dataFormatada = `${ano}/${mes}/${dia} ${horas}:${minutos}:${segundos}`;

    // Converter regtemp.quantidade de volta para string
    regtemp.quantidade = newQuantidade.toString();
    
    DBService.atualiza(regtemp.id, regtemp)
      .then(() => {
        outro()
      })
      .catch((e) => {
        console.log(e);
      });

      DBService2.criaRegistro({
        id: regtemp.id,
        quantidade: novaQuantidade.toString(),
        precomedio: valorVenda,
        data: dataFormatada,
        tipo: "venda"
      })
        .then(() => {
          
        })
        .catch((e) => {
          console.log(e);
        });

  };
  

  const outro = () => {
    handleClick("Seus dados foram atualizados", "success");
  };

  const handleClick = (dados, variant) => {
    enqueueSnackbar(dados, { variant });
  };

  return (
    <div className="submit-form">
      <div>
        <Paper style={{ maxHeight: 600, overflow: 'auto', backgroundColor: '#1e2129', color: '#98a0b4' }}>
        <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Valor venda</InputGroup.Text>
              <FormControl
                type="number"
                id="valorVenda"
                required
                name="valorVenda"
                value={valorVenda}
                onChange={(e) => setValorVenda(e.target.value)} // Atualizar o estado da Nova Quantidade
              />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Quantidade Papéis</InputGroup.Text>
              <FormControl
                type="number"
                id="novaquantidade"
                required
                name="novaquantidade"
                value={novaQuantidade}
                onChange={(e) => setNovaQuantidade(e.target.value)} // Atualizar o estado da Nova Quantidade
              />
            </InputGroup>
          </div>
        </Paper>
        <button onClick={Vender} className="btn btn-danger btn-sm">
          Vender
        </button>
      </div>
    </div>
  );
};

export default function IntegrationNotistack(registro) {
  return (
    <SnackbarProvider maxSnack={3}>
      <Venda registro={registro} />
    </SnackbarProvider>
  );
}
