import React, { useState } from "react";
import ListadeResgistros from '.';
import { Modal, Button } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";

export default function FaturaModal(id_secao) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <Tooltip title="Faturas">
      <Button className="btn btn-primary btn-sm" onClick={handleShow} >
      <i class="fa fa-eye" aria-hidden="true"></i>
          </Button>
          </Tooltip>
      <Modal className="special_modal" size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Mensalidade
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListadeResgistros id={id_secao}/>
        </Modal.Body>
        <Modal.Footer>
        <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
