import React, { useState, useEffect } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore"
import Paper from "@material-ui/core/Paper";
import { Table} from 'react-bootstrap';
import QrModal from "../Qr_code_conexao/modal";
import MsgModal from "../Mensagem/modal";
import EnvioMassaModal from "../Massa/modal";
import FaturaModal from "../ListarFaturas/Modal";
import RstModal from "../Reset/modal"
import moment from 'moment';
import 'moment/locale/pt-br';
import { db } from "../../../firebase";


const ListadeResgistros = (currentUser) => {

  const [regtemp] = useState(currentUser.usuario.usuario.usuario.uid);
  const [registros, setRegistros] = useState([])

  useEffect(() => {
    const usersCollectionRef = query(collection(db, 'secao'), where("token", "==", regtemp), where("tipo", "==", "api"));
    const unsub = onSnapshot(usersCollectionRef, (querySnapshot) => {
      setRegistros(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
    return unsub;
  }, []);

  return (

    <div>
      {registros.length === 0 ? <div><div class="alert alert-warning" role="alert">
        Ainda não temos nenhuma sessão cadastrada, clique em "+ Nova sessão" para cadastrar.
      </div></div> : <div>
        <div className="card mb-4">
          <div style={{ width: '100%' }}>
            <ul className="list-group"><Paper style={{ maxHeight: 400, overflow: 'auto' }}>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Id</th>
                    <th>Token</th>
                    <th>Estado</th>
                    <th>Validade</th>
                    <th>Situação</th>
                    <th>Mensalidade</th>
                    <th>Funções</th>
                    <th>Reiniciar</th>
                  </tr>
                </thead>
                <tbody>
                  {registros.map((registro) => (
                    <tr>
                      <td>{registro.nomesecao}</td>
                      <td>{registro.id_secao}</td>
                      <td>{registro.token}</td>
                      <td>{registro.estado === "Conectar" ? <QrModal id={registro.id_secao} /> : <td>{registro.estado}</td>}</td>
                      <td>{moment(registro.validade.toDate().toString()).format('L')}</td>
                      <td>{registro.pagamento}</td>
                      <td><FaturaModal id={registro.id_secao} /></td>
                      <td>
                        {registro.estado === "Autenticado" ? <MsgModal id={registro} /> : <div></div>}
                        {registro.estado === "Autenticado" ? <EnvioMassaModal id={registro} /> : <div></div>}
                      </td>
                      <td>{registro.pagamento === "Pago" || registro.pagamento === "Teste" ? <RstModal id={registro} /> : <div></div>}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Paper>
            </ul>
          </div>
        </div>
      </div>}


    </div>
  );
}
export default ListadeResgistros;