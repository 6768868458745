import React, {useState, useEffect} from "react";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import axios from 'axios';
import { db } from "../../../../firebase";
import Erro from "../../Erro/erro";

export default function QR(registro) {

    const [regtemp, setRegtemp] = useState({
        qrcode: "",
    });
    const registro_temp = registro.registro.registro

    const data = JSON.stringify({
        "valor": registro_temp.valor,
      });
    
      const config = {
        method: 'post',
        url: 'https://agteletronic.com.br/gerarpix/gerarCobranca',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      useEffect(() => {
        gerar();
      }, []);

      const gerar = () => {

        axios(config)
        .then(function (response) {
        setRegtemp({qrcode: response.data.qrcode})
        const gravar = {
            criado: registro_temp.criado,
            expiracao: registro_temp.expiracao,
            estado: registro_temp.estado,
            id_secao: registro_temp.id_secao,
            nomesecao: registro_temp.nomesecao,
            qrcode: "",
            txid: response.data.txid,
            uid: registro_temp.uid,
            valor: registro_temp.valor,
            validade: registro_temp.validade
        }

        const docRef = doc(db, "faturas", registro_temp.id);
        setDoc(docRef, gravar)
        .then(docRef => {
            
        })
        .catch(error => {
          alert('Desculpe-nos, mas estamos fazendo uma manutenção temporária em nosso site. Por favor, tente novamente mais tarde. Obrigado pela sua compreensão.');
            Erro("Erro ao gerar fatura.")
        })
        })
        .catch(function (error) {
          alert('Desculpe-nos, mas estamos fazendo uma manutenção temporária em nosso site. Por favor, tente novamente mais tarde. Obrigado pela sua compreensão.');
          Erro("Erro ao gerar fatura.")
        });
        

      }
      
    return (
        <div className="container">
            <div className="col-md-12">
                <div className="panel panel-primary">
                    <div className="panel-body">
                        {regtemp.qrcode == "" ? <div><i class="fa fa-spinner fa-pulse fa-5x fa-fw"></i> Carregando...</div> : <div> <p><strong>Aponte a camera do seu aplicativo de pagamentos para o Qr code.</strong></p> <img src= {regtemp.qrcode}/> <p><br></br> <strong>Ao iniciar o pagamento mantenha está janela aberta até fechar automaticamente. </strong></p></div>}
                    </div>
                </div>
            </div>
        </div>
    );
}