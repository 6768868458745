import React, { useState } from "react";
import FormatarNumeros from './retiraPais.js';
import { Modal, Button } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { Card } from "antd";
import { ListGroup } from 'react-bootstrap';

export default function RetiraModal(currentUser) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ListGroup.Item onClick={handleShow}>Retira País</ListGroup.Item>
      <Modal className="special_modal" size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Retira País
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormatarNumeros/>
        </Modal.Body>
        <Modal.Footer>
          <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
