import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ListadeResgistros from "./listaderegistros";
import AddModal from "./modalAdd";
import { Card } from "antd";
import Tooltip from "@material-ui/core/Tooltip";
const { Meta } = Card;


export default function MicroservicosModal(currentUser) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Card
        onClick={handleShow}
        hoverable
        style={{
          width: 170,
          marginleft: 'auto',
          marginright: 'auto',
        }}
        cover={
          <div className="center2" >
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-card-checklist" viewBox="0 0 16 16">
              <g color="#556278">
              <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
              </g>
            </svg>
          </div>
        }
      >
        <Meta
          title="Microserviços"
          description="Informações sobre os microserviços em funcionamento."
          onClick={handleShow}
        />
      </Card>
      <Modal className="special_modal" size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Microserviços
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListadeResgistros usuario={currentUser}/>
        </Modal.Body>
        <Modal.Footer>
          <AddModal />
          <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
