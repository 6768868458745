import React, { useState} from "react";
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Erro from "../Erro/erro"

export default function Dlt(registro) {

   const [regtemp] = useState({
        id: registro.id.id.id_secao,
        token: registro.id.id.token
    });

    const handleFormSubmit = (e) => {
        e.preventDefault()
        axios({
            method: 'POST',
            url: 'https://agteletronic.com.br/delete',
            //url: 'http://localhost:4003/delete',
            data: regtemp
        })
            .then(function (response) {
                //handle success
                alert('Sessão deletada com sucesso.');
            })
            .catch(function (response) {
                alert('Desculpe-nos, mas estamos fazendo uma manutenção temporária em nosso site. Por favor, tente novamente mais tarde. Obrigado pela sua compreensão.');
                Erro("Problema ao processar pedido de delete no site.")
            });
    }


    return (
        <div className="container">

            <form>
            <Alert variant="danger">
                Atenção!
                Ao clicar em "Deletar sessão" não poderá retornar atrás.
                </Alert>
                <input type="submit" className="btn btn-primary btn-block btn-sm" onClick={e => handleFormSubmit(e)} value="Deletar sessão" />
            </form>
        </div>

    );
}