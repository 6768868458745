import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import {collection, query, orderBy, onSnapshot, where} from "firebase/firestore"
import { Table, Button } from 'react-bootstrap';
import QrModal from "./Qr_code/modal";
import { db } from "../../../firebase";
import moment from 'moment';
import 'moment/locale/pt-br';

const ListadeResgistros = (id_secao) => {
  const [registros, setRegistros] = useState([])

  useEffect(() => {
    const usersCollectionRef = query(collection(db, 'faturas'), where("id_secao", "==", id_secao.id.id), orderBy("validade", "desc"));
    const unsub = onSnapshot(usersCollectionRef, (querySnapshot) => {
     
      setRegistros(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }
        ))
        
      );
    });
    return unsub;
  }, []);

  return (
    <div class="card mb-4">
      <div style={{ width: '100%' }}>
        <ul className="list-group">
          <Paper style={{ maxHeight: 450, overflow: 'auto' }}>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Valor</th>
                  <th>Próxima Fatura</th>
                  <th>Estado</th>
                  <th>Pix</th>
                  <th>Código identificador</th>
                </tr>
              </thead>
              <tbody>
              {registros.map((registro) => (
                
                <tr>
                <td>{registro.nomesecao}</td>
                <td>R$ {registro.valor}</td>
                <td>{registro.validade === "" ? <td></td>: moment(registro.validade.toDate().toString()).format('L')}</td>
                <td>{registro.estado}</td>
                {registro.estado === "Aberto" ?  <td><QrModal registro={registro}/></td>:<td></td> }
                <td>{registro.id}</td>
              </tr>
              ))}
              </tbody>
            </Table>
          </Paper>
        </ul>
      </div>
    </div>
  );
}
export default ListadeResgistros;