import React, { useState, useEffect } from "react";
import { InputGroup, FormControl } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { SnackbarProvider, useSnackbar } from "notistack";
import DBService from "./dbservice";
import DBService2 from "./dbservice2";

const Compra = (registro) => {
  const [regtemp, setRegtemp] = useState(registro.registro.registro.registro);
  const [quantidadeCompra, setQuantidadeCompra] = useState(0);
  const [valorCompra, setValorCompra] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Atualize o estado local quando as propriedades 'registro' mudarem
    setRegtemp(registro.registro.registro.registro);
  }, [registro]);

  const Comprar = () => {
    const valorultimacompra = quantidadeCompra * valorCompra;
    const valortotalanterior = parseFloat(regtemp.precomedio) * Number(regtemp.quantidade);
    const quantidadedepapeis = Number(quantidadeCompra) + Number(regtemp.quantidade);
    const ultimovalortotal = Number(valorultimacompra) + Number(valortotalanterior);
    const precomediofinal = Number(ultimovalortotal) / Number(quantidadedepapeis);
    const precomediofixado = precomediofinal;

    if (!isNaN(quantidadeCompra) && !isNaN(valorCompra)) {
      // Use a função de atualização do estado para garantir a atualização correta
      setRegtemp((prevRegtemp) => ({
        ...prevRegtemp,
        quantidade: quantidadedepapeis.toString(),
        precomedio: precomediofixado.toString(2),
      }));
      // Chame gravaRegistro com o valor atualizado
      gravaRegistro({
        ...regtemp, // Use o valor mais recente de regtemp
        quantidade: quantidadedepapeis.toString(),
        precomedio: precomediofinal.toString(),
      });
    }
  };

  const gravaRegistro = (regtemp2) => {
    const dataAtual = new Date();

    const ano = dataAtual.getFullYear();
    const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0'); // Os meses são base 0
    const dia = dataAtual.getDate().toString().padStart(2, '0');
    const horas = dataAtual.getHours().toString().padStart(2, '0');
    const minutos = dataAtual.getMinutes().toString().padStart(2, '0');
    const segundos = dataAtual.getSeconds().toString().padStart(2, '0');

    const dataFormatada = `${ano}/${mes}/${dia} ${horas}:${minutos}:${segundos}`;

    DBService.atualiza(regtemp.id, regtemp2)
      .then(() => {
        outro();
      })
      .catch((e) => {
        console.log(e);
      });


    DBService2.criaRegistro({
      id: regtemp.id,
      quantidade: quantidadeCompra.toString(),
      precomedio: valorCompra.toString(),
      data: dataFormatada,
      tipo: "compra"
    })
      .then(() => {

      })
      .catch((e) => {
        console.log(e);
      });
  };

  const outro = () => {
    handleClick("Seus dados foram atualizados", "success");
  };

  const handleClick = (dados, variant) => {
    enqueueSnackbar(dados, { variant });
  };

  return (
    <div className="submit-form">
      <div>
        <Paper style={{ maxHeight: 600, overflow: 'auto', backgroundColor: '#1e2129', color: '#98a0b4' }}>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Valor Compra</InputGroup.Text>
              <FormControl
                type="number"
                id="valorCompra"
                required
                name="valorCompra"
                value={valorCompra}
                onChange={(e) => setValorCompra(e.target.value)} // Atualizar o estado da Nova Quantidade
              />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Quantidade Papéis</InputGroup.Text>
              <FormControl
                type="number"
                id="quantidadeCompra"
                required
                name="quantidadeCompra"
                value={quantidadeCompra}
                onChange={(e) => setQuantidadeCompra(e.target.value)} // Atualizar o estado da Nova Quantidade
              />
            </InputGroup>
          </div>
        </Paper>
        <button onClick={Comprar} className="btn btn-success btn-sm">
          Comprar
        </button>
      </div>
    </div>
  );
};

export default function IntegrationNotistack(registro) {
  return (
    <SnackbarProvider maxSnack={3}>
      <Compra registro={registro} />
    </SnackbarProvider>
  );
}
