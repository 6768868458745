import React, { useState } from 'react';
import { Modal, Input, Form, Select, Button, message } from 'antd';

import DBService from './dbservice';

const AddModal = ({ usuario }) => {
  const [visible, setVisible] = useState(false);
  const [regtemp, setRegtemp] = useState({
    uid: '',
    criado: '',
    botoes: '1',
    moduleId: '',
    text: '',
  });

  const [errors, setErrors] = useState({
    text: '',
    botoes: '',
    moduleId: '',
  });

  const [botoesValido, setBotoesValido] = useState(true);

  const uid = usuario?.usuario?.usuario?.uid;

  const handleShow = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const gravaRegistro = (event) => {
    event.preventDefault();

    // Validar campos
    const newErrors = {};
    if (!regtemp.text) {
      newErrors.text = 'Por favor, preencha este campo.';
    }
    if (!regtemp.botoes) {
      newErrors.botoes = 'Por favor, selecione um tipo.';
    }
    if (!regtemp.moduleId) {
      newErrors.moduleId = 'Por favor, preencha este campo.';
    }

    // Atualizar estados de erros
    setErrors(newErrors);

    // Se houver algum erro, interromper e exibir mensagens
    if (Object.values(newErrors).some((error) => error !== '')) {
      return;
    }

    // Se não houver erros, continuar com a criação do registro
    const data = {
      uid: uid,
      criado: new Date(),
      botoes: regtemp.botoes,
      moduleId: regtemp.moduleId,
      text: regtemp.text,
    };

    DBService.criaRegistro(data)
      .then(() => {
        outro();
        novoRegistro();
        handleClose();
      })
      .catch((e) => {
        
      });
  };

  const outro = () => {
    handleClick('Seus dados foram gravados', 'success');
  };

  const handleClick = (dados, variant) => {
    message[variant](dados);
  };

  const alteracaoManual = (value, name) => {
    setRegtemp({ ...regtemp, [name]: value });

    // Validar campos em tempo real (pode ser ajustado conforme necessário)
    const newErrors = { ...errors };
    if (name === 'text' && !value) {
      newErrors.text = 'Por favor, preencha este campo.';
    }
    if (name === 'botoes' && !value) {
      newErrors.botoes = 'Por favor, selecione um tipo.';
    }
    if (name === 'moduleId' && !value) {
      newErrors.moduleId = 'Por favor, preencha este campo.';
    }

    // Atualizar estados de erros
    setErrors(newErrors);
  };

  const novoRegistro = () => {
    setRegtemp({
      uid: '',
      criado: '',
      botoes: '1',
      moduleId: '',
      text: '',
    });
    setErrors({
      text: '',
      botoes: '',
      moduleId: '',
    });
  };

  return (
    <>
      <button onClick={handleShow} className="btn btn-primary btn-sm">
        <i className="fa fa-plus-circle" aria-hidden="true"></i>
      </button>
      <Modal
        centered
        visible={visible}
        onCancel={handleClose}
        footer={[
          <Button key="cancel" onClick={handleClose}>
            Fechar
          </Button>,
          <Button key="submit" type="primary" onClick={gravaRegistro}>
            Enviar
          </Button>,
        ]}
      >
        <h3>Adicionar Módulo</h3>
        <div className="submit-form">
          <div>
            <Input
              placeholder="Digite o nome do módulo"
              type="text"
              id="text"
              value={regtemp.text}
              onChange={(e) => alteracaoManual(e.target.value, 'text')}
            />
            {errors.text && <small className="text-danger">{errors.text}</small>}
          </div>

          <div className="form-group">
            <Select
              value={regtemp.botoes}
              onChange={(value) => {
                alteracaoManual(value, 'botoes');
                setBotoesValido(value !== '');
              }}
              style={{ width: '100%' }}
              placeholder="Selecione um tipo"
            >
              <Select.Option value="1">Liga/Desliga</Select.Option>
              <Select.Option value="0">Pulso</Select.Option>
            </Select>
            {errors.botoes && <small className="text-danger">{errors.botoes}</small>}
          </div>
          <Input
            placeholder="Digite o ID do módulo"
            type="text"
            id="moduleId"
            value={regtemp.moduleId}
            onChange={(e) => alteracaoManual(e.target.value, 'moduleId')}
          />
          {errors.moduleId && <small className="text-danger">{errors.moduleId}</small>}
        </div>
      </Modal>
    </>
  );
};

export default AddModal;
