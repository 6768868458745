import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { db } from '../../../firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import AddStockModal from './AddStockModal';
import TabelaModalDividendos from "../Dividendos/modaltabela";
import TabelaModalDividendos2 from "../Dividendos/modaltabelatotal";
import ModaleditCotacao from "./modalEdit";
import ModalCompra from './ModalCompra';
import ModalVenda from './ModalVenda';
import DBService from "./dbservice";
import Tooltip from "@material-ui/core/Tooltip";

function StockTable(currentUser) {

  const [stocks, setStocks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [areTotalsVisible, setAreTotalsVisible] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const deletaRegistro = (registro) => {
    if (window.confirm("Você vai mesmo deletar isso?")) {
      DBService.remove(registro)
        .then(() => {
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };


  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'cotacoes'), (snapshot) => {
      const stocksData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStocks(stocksData);
    });

    return unsubscribe;
  }, []);

  const formatTimestamp = (timestamp) => {
    const dateParts = timestamp.split(' ');
    const date = dateParts[0].split('-').reverse().join('/');
    const time = dateParts[1];

    return `${date} ${time}`;
  };

  return (
    <div>
      <div>
        <Button variant="primary" onClick={handleShowModal}>
          Adicionar Papel
        </Button>
        <br />
      </div>
      <div>
        <div className="card mb-4">

          <Table responsive>
            <thead>
              <tr>
                <th>Papel</th>
                <th>Preço médio</th>
                <th>Cotação</th>
                <th>Diferença</th>
                <th>Percentual</th>
                <th>Quantidade</th>
                <th>Valor Pago</th>
                <th>Valor Atual</th>
                <th>Diferença</th>
                <th>Última atualização</th>
                <th>Dividendos</th>
                <th>Editor</th>
              </tr>
            </thead>
            <tbody>
              {stocks.map((stock) => {
                const numero = parseFloat(stock.last_price) - parseFloat(stock.precomedio);
                const numeroArredondado = numero.toFixed(2);
                const percentual = ((parseFloat(stock.last_price) - parseFloat(stock.precomedio)) / parseFloat(stock.precomedio) * 100).toFixed(2);
                const valorTotal = (parseFloat(stock.precomedio) * stock.quantidade).toFixed(2);
                return (
                  <tr key={stock.id}>
                    <td>
                      <a href={`https://www.google.com/search?q=${stock.id.slice(0, -3)}+cota%C3%A7%C3%A3o`} target="_blank" rel="noopener noreferrer">
                        {stock.id}
                      </a>
                    </td>
                    <td>{Number(stock.precomedio).toFixed(2)}</td>
                    <td>{parseFloat(stock.last_price).toFixed(2)}</td>
                    {numero < 0 ? <td style={{ backgroundColor: 'red' }}>{numeroArredondado}</td> : <td>{numeroArredondado}</td>}
                    {percentual < 0 ? <td style={{ backgroundColor: 'red' }}>{percentual}%</td> : <td>{percentual}%</td>}
                    <td>{stock.quantidade}</td>
                    <td>{valorTotal}</td>
                    <td>{parseFloat(stock.last_price * stock.quantidade).toFixed(2)}</td>
                    <td>{parseFloat(stock.last_price * stock.quantidade - parseFloat(stock.precomedio) * stock.quantidade).toFixed(2)}</td>
                    <td>{formatTimestamp(stock.last_update)}</td>
                    <td><TabelaModalDividendos stock={stock.id} precocompra={valorTotal} /></td>

                    <td><Tooltip title="Deletar"><button onClick={() => deletaRegistro(stock.id)} className="btn btn-danger btn-sm">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                    </Tooltip>
                      <ModaleditCotacao registro={stock} />
                      <ModalCompra registro={stock} />
                      <ModalVenda registro={stock} />
                    </td>


                  </tr>
                );
              })}
              <tr>
                <td colSpan="5">Total</td>
                <td>
                  <Button variant="info" onClick={() => setAreTotalsVisible(!areTotalsVisible)}>
                    {areTotalsVisible ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                  </Button>
                </td>
                <td>
                  {areTotalsVisible && (
                    // Renderize os totais da coluna "Valor Total" aqui
                    stocks.reduce((total, stock) => total + parseFloat(stock.precomedio) * stock.quantidade, 0).toFixed(2)
                  )}
                </td>
                <td>
                  {areTotalsVisible && (
                    // Renderize os totais da nova coluna aqui
                    stocks.reduce((total, stock) => total + parseFloat(stock.last_price * stock.quantidade), 0).toFixed(2)
                  )}
                </td>
                <td>
                  {areTotalsVisible && (
                    stocks.reduce((total, stock) => total + (parseFloat(stock.last_price) * stock.quantidade), 0) -
                    stocks.reduce((total, stock) => total + (parseFloat(stock.precomedio) * stock.quantidade), 0)
                  ).toFixed(2)}
                </td>

                <td>
                  {areTotalsVisible && (((
                    stocks.reduce((total, stock) => total + (parseFloat(stock.last_price) * stock.quantidade), 0) -
                    stocks.reduce((total, stock) => total + (parseFloat(stock.precomedio) * stock.quantidade), 0)
                  ) * 100 / (stocks.reduce((total, stock) => total + (parseFloat(stock.precomedio) * stock.quantidade), 0).toFixed(2))).toFixed(2)) + "%"}
                </td>

                <td><TabelaModalDividendos2 valorTotal={stocks.reduce((total, stock) => total + parseFloat(stock.precomedio) * stock.quantidade, 0).toFixed(2)} /></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>
        <AddStockModal show={showModal} handleClose={handleCloseModal} />
      </div>
    </div>
  );
}

export default StockTable;
