import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import axios from 'axios';

function ImageUpload() {
  const [file, setFile] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [error, setError] = useState(null);
  const [fileSize, setFileSize] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    // Calcular o tamanho do arquivo em kilobytes
    const sizeInKB = selectedFile.size / 1024;
    setFileSize(sizeInKB.toFixed(2) + ' KB');
  };

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      axios.post('https://suporte.agteletronic.com.br/upload', formData)
        .then(response => {
          console.log(response.data);
          setImagePath('https://suporte.agteletronic.com.br/imagem/' + response.data.imagePath);
          setError(null);
        })
        .catch(error => {
          console.error('Erro ao fazer upload:', error);
          setError('Erro ao fazer upload. Por favor, tente novamente.');
        });
    } else {
      console.error('Nenhum arquivo selecionado');
      setError('Nenhum arquivo selecionado');
    }
  };

  return (
    <div>
      <h1>Upload de Imagem</h1>

      <div class="input-group mb-3">
        <input type="file" onChange={handleFileChange} />
        <Button onClick={handleUpload}>Enviar</Button>
      </div>


      {error && <p style={{ color: 'red' }}>{error}</p>}
      {fileSize &&
        (<p>Tamanho do Arquivo: {fileSize}</p>)
      }
      {imagePath && (
        <div>
          <h2>Imagem Carregada</h2>
          <img src={imagePath} alt={imagePath} className="image-preview" />
          <p>Tamanho do Arquivo: {fileSize}</p>
        </div>
      )}
    </div>
  );
}

export default ImageUpload;
