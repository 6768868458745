import React from 'react';
import { Button } from 'antd';
import axios from 'axios';
import { PoweroffOutlined, StopOutlined, ThunderboltOutlined } from '@ant-design/icons'; // Importe os ícones necessários


const url = "https://agteletronic.com.br/sendData";

const Comandos = (registro) => {

    var topic = registro.topic;
    var botoes = registro.botoes;

    const buttonCollor = '#2eb2ff';
    const fontCollor = '#FFFFFF';


    const handleLigarClick = () => {
        // Lógica para lidar com o clique no botão "Ligar"
        axios.post(url, { topic: topic, payload: 'ligar' })
            .then(response => {

            })
            .catch(error => {
                // Lógica para lidar com erros na solicitação POST
                console.error('Erro na solicitação POST:', error);
            });
    };

    const handleDesligarClick = () => {
        // Lógica para lidar com o clique no botão "Desligar"
        axios.post(url, { topic: topic, payload: 'desligar' })
            .then(response => {
                // Lógica para lidar com a resposta da API após a solicitação POST bem-sucedida
                console.log('Resposta da API:', response.data);
            })
            .catch(error => {
                // Lógica para lidar com erros na solicitação POST
                console.error('Erro na solicitação POST:', error);
            });
    };

    const handlePulsoClick = () => {
        // Lógica para lidar com o clique no botão "Pulso"
        axios.post(url, { topic: topic, payload: 'pulso' })
            .then(response => {
                // Lógica para lidar com a resposta da API após a solicitação POST bem-sucedida
                console.log('Resposta da API:', response.data);
            })
            .catch(error => {
                // Lógica para lidar com erros na solicitação POST
                console.error('Erro na solicitação POST:', error);
            });
    };

    return (
        <div style={{ textAlign: 'center' }}>
            {botoes === "1" ? (
                <>
                    <Button
                        onClick={handleLigarClick}
                        icon={<PoweroffOutlined style={{ fontSize: '24px' }} />}
                        style={{
                            backgroundColor: buttonCollor,
                            width: '50px',
                            height: '50px',
                            color: fontCollor,
                            marginRight: '3px',
                            borderRadius: '50%', // Adiciona bordas circulares
                        }}
                    />
                    <Button
                        onClick={handleDesligarClick}
                        icon={<StopOutlined style={{ fontSize: '24px' }} />}
                        style={{ backgroundColor: '#e83b31', width: '50px', height: '50px', color: fontCollor }}
                    />

                </>
            ) : (
                <Button
                    onClick={handlePulsoClick}
                    icon={<ThunderboltOutlined style={{ fontSize: '24px' }} />}
                    style={{
                        backgroundColor: buttonCollor,
                        width: '50px',
                        height: '50px',
                        color: fontCollor,
                        marginRight: '3px',
                        borderRadius: '50%', // Adiciona bordas circulares
                    }}
                />
            )}
        </div>

    );
};

export default Comandos;
