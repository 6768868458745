import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Form, Button } from 'react-bootstrap';
import { db } from "../../../firebase";
import axios from 'axios';
import { Snackbar, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    snackbar: {
      [theme.breakpoints.down('xs')]: {
        bottom: 90,
      },
    },
  })
);

function GeradordeConteudo() {
  const classes = useStyles();
  const [registros, setRegistros] = useState([]);
  const [valorSelecionado, setValorSelecionado] = useState('');
  const [descricaoSelecionada, setDescricaoSelecionada] = useState('');
  const [exemploSelecionado, setExemploSelecionado] = useState('');
  const [pergunta, setPergunta] = useState('');
  const [resposta, setResposta] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    carregarColecao();
  }, []);

  async function carregarColecao() {
    const querySnapshot = await getDocs(collection(db, 'GeradorDePrompt'));
    const documentos = querySnapshot.docs.map(doc => {
      const { nome, descricao, exemplo } = doc.data();
      return { nome, descricao, exemplo };
    });
    setRegistros(documentos);
  }

  const showSnackbar = () => {
    setSnackbarOpen(true);
  };

  async function enviarFormulario() {
    try {
      const response = await axios.post('https://suporte.agteletronic.com.br/chat', {
        "message": pergunta,
        "informacoes": descricaoSelecionada
      });
      const respostaDoServidor = response.data.response.content;

      // Atualizar o estado com a resposta do servidor
      setResposta(respostaDoServidor);

      // Exibir o snackbar
      showSnackbar();
    } catch (error) {
      // Trate qualquer erro de envio aqui
      console.error(error);
      // Ou exiba uma mensagem de erro ao usuário
      alert('Ocorreu um erro ao enviar os dados.');
    }
  }

  return (
    <div>
      <Form.Select
        style={{ width: '500px' }}
        value={valorSelecionado}
        onChange={(e) => {
          const selecionado = registros.find(
            (registro) => registro.nome === e.target.value
          );
          setValorSelecionado(e.target.value);
          setDescricaoSelecionada(selecionado.descricao);
          setExemploSelecionado(selecionado.exemplo);
        }}
      >
        <option value="">Selecione um nome</option>
        {registros.map((registro, index) => (
          <option key={index} value={registro.nome}>
            {registro.nome}
          </option>
        ))}
      </Form.Select>
      {valorSelecionado && (
        <div>
          <h2>Prompt:</h2>
          <p>{descricaoSelecionada}</p>
          <h2>Dados:</h2>
          <Form.Control
            as="textarea"
            value={pergunta}
            onChange={(e) => setPergunta(e.target.value)}
            placeholder="Digite a pergunta"
            rows={8}
          />
          <Button onClick={() => setPergunta('')}>Limpar</Button>
          <Button onClick={enviarFormulario}>Enviar</Button>
          <br /><br />
          <Form.Control
            as="textarea"
            value={resposta}
            placeholder="Resposta do servidor"
            rows={8}
          />
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            message="Dados enviados com sucesso!"
            className={classes.snackbar}
          />
        </div>
      )}
    </div>
  );
}

export default GeradordeConteudo;
