import React, { useState } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DBService from "./dbservice";

const Registro = (props) => {
const estadoInicial = {
key: null,
uid: "", 
criado: "", 
apagar: "",titulo: "",
imagem: "",
texto: "",
link: "",

}; 

const [regtemp, setRegtemp] = useState(estadoInicial); 
const [mensagem, setMensagem] = useState(""); 
const { registro } = props;

 if (regtemp.id !== registro.id) { setRegtemp(registro);
 setMensagem(""); 
} 

const alteracaoManual = (event) => { 
const { name, value } = event.target; 
setRegtemp({...regtemp, [name]: value }); 
};

const atualizaRegistro = () => {
const data = {
uid: regtemp.uid,
criado: regtemp.criado,
apagar: regtemp.apagar,titulo: regtemp.titulo,
imagem: regtemp.imagem,
texto: regtemp.texto,
link: regtemp.link,
}; 
DBService.atualiza(regtemp.id, data) 
.then(() => {
setMensagem("Registro atualizado com exito!");
handleClick();
props.refreshList();
})
.catch((e) => {
console.log(e);
});
};

  const deletaRegistro = () => {
    try {
      if (window.confirm("Você vai mesmo deletar isso?")) {
        DBService.remove(regtemp.id)
      .then(() => {
        props.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
      }
    } catch (err) {
      console.log(err);
    }
  };

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

return ( 
<div> 
{regtemp ? ( 
<div className="edit-form">
<form>
<InputGroup className="mb-3 d-flex flex-row-reverse" ><i>{new Date(regtemp.criado.seconds * 1000).toLocaleString()}</i></InputGroup>    <div className="form-group">
<InputGroup className="mb-3">
        <InputGroup.Text>Titulo</InputGroup.Text>
            <FormControl
            type="text"
            id="titulo"
            required
            value={regtemp.titulo}
            onChange={alteracaoManual}
            name="titulo" />
</InputGroup>
    </div>
    <div className="form-group">
<InputGroup className="mb-3">
        <InputGroup.Text>Imagem</InputGroup.Text>
            <FormControl
            type="text"
            id="imagem"
            required
            value={regtemp.imagem}
            onChange={alteracaoManual}
            name="imagem" />
</InputGroup>
    </div>
    <div className="form-group">
<InputGroup className="mb-3">
        <InputGroup.Text>Texto</InputGroup.Text>
            <FormControl
            type="text"
            id="texto"
            required
            value={regtemp.texto}
            onChange={alteracaoManual}
            name="texto" />
</InputGroup>
    </div>
    <div className="form-group">
<InputGroup className="mb-3">
        <InputGroup.Text>Link</InputGroup.Text>
            <FormControl
            type="text"
            id="link"
            required
            value={regtemp.link}
            onChange={alteracaoManual}
            name="link" />
</InputGroup>
    </div>
</form>

          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={deletaRegistro}
          >
            Deletar
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={atualizaRegistro}
          >
            Atualizar
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => window.open(regtemp.link, '_blank')}
            target="_blank"
            rel="noopener noreferrer"
          >
            Abrir Link
          </button>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Registro atualizado com exito!
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div>
          <br />
          <p>Clique no Registro...</p>
        </div>
      )}
    </div>
  );
};

export default Registro;
