import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import Venda from "./Venda";

export default function ModalVenda(registro) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Tooltip title="Venda">
        <button onClick={handleShow} className="btn btn-dark btn-sm">
        <i class="fa fa-exchange" aria-hidden="true"></i>
        </button>
      </Tooltip>
      <Modal
        size="sm"
        centered
        className="special_modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Vender - {registro.registro.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Venda registro={registro} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

