import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import Formulario from "./Formulario";
import ModalGenerico from "../modalGenerico/modal";

export default function FormularioModal2(id) {
  const sessao = id.id
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Tooltip title="Formulário de Configuração do Atendente Virtual">
        <button onClick={handleShow} className="btn btn-primary btn-sm">
        <i class="fa fa-android" aria-hidden="true"></i>
        </button>
      </Tooltip>
      <Modal
        size="xl"
        className="special_modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Central de Tomadas de Decisões
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formulario sessao = {sessao}/>
        </Modal.Body>
        <Modal.Footer>
        <ModalGenerico informacao ={"Aqui é o local onde você administra as configurações do seu chatbot. É a 'Central de Tomada de Decisões'. Complete os campos do formulário para fornecer informações essenciais que seu chatbot usará durante as interações com os clientes. Não se esqueça de clicar no botão 'Salvar' para assegurar a preservação de todas as configurações. No formulário, você tem a possibilidade de dar um nome ao seu atendente, conferindo-lhe uma personalidade distinta. Além disso, lembre-se de que o treinamento do seu chatbot pode ser aprimorado ao longo do tempo. À medida que as necessidades do seu negócio evoluem, você pode adicionar ou modificar informações para garantir que seu chatbot continue oferecendo um atendimento impecável. Aproveite ao máximo os recursos disponíveis, como os botões 'Quero Exemplo' para obter sugestões de serviços comumente praticados por empresas do mesmo ramo, 'Formatar Texto' para organizar e corrigir possíveis erros de digitação, e 'Sintetizar Texto' para reduzir o tamanho do texto mantendo o mesmo significado. Isso permitirá que você ofereça uma experiência aprimorada aos seus clientes."}/>
        <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}

