import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import QR from "./index";
import Tooltip from "@material-ui/core/Tooltip";

export default function QrModal(registro) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="btn btn-primary btn-sm" onClick={handleShow} >
            Realizar pagamento
          </Button>
      <Modal className="special_modal" size="sm" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Mensalidade
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <QR registro = {registro}/>
        </Modal.Body>
        <Modal.Footer>
        <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
