import React from "react";
import { Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://agteletronic.com.br">
        AGTELETRONIC
      </Link>{" "}
      2020 {" - "}
      {new Date().getFullYear()}
      {"."}
      <br/>
      {"Todos os direitos reservados © "}
    </Typography>
  );
};

export default Copyright;
