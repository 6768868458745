import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import AddModal from "./modalAdd";
import ListadeResgistrosTabela from "./tabela";
import PrintButton from "./tabela";
import { Card } from "antd";
const { Meta } = Card;
const descricao = "Alterar aqui"; // Fazer alteração da descrição aqui
const titulo = "Pedidos";


export default function TabelaModalPedidos(currentUser) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Card
        onClick={handleShow}
        hoverable
        style={{
          width: 170,
          marginleft: 'auto',
          marginright: 'auto',
        }}
        cover={
          //https://icons.getbootstrap.com/
          <div className="center2" >
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-card-checklist" viewBox="0 0 16 16">
              <g color="#556278">
                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
              </g>
            </svg>
          </div>
        }
      >
        <Meta
          title={titulo}
          description={descricao}
          onClick={handleShow}
        />
      </Card>
      <Modal className="special_modal" size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {titulo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrintButton usuario={currentUser} />
        </Modal.Body>
        <Modal.Footer>
          <AddModal />
          <Button variant="secondary btn-sm" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
