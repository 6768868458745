import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { SnackbarProvider, useSnackbar } from 'notistack';

const API_ENDPOINT = 'https://agteletronic.com.br/sendData';

function MqttModal(sessao) {
    const [show, setShow] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const Sessao = sessao.sessao.sessao.id;
    const Porta = sessao.sessao.sessao.porta;

    const handleApiRequest = async (comando, novoTopico) => {
        try {
            const response = await fetch(API_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ topic: novoTopico, payload: comando }),
            });

            if (response.ok) {
                enqueueSnackbar('Command sent successfully!', { variant: 'success' });
            } else {
                enqueueSnackbar('Failed to send command', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error sending command:', error);
            enqueueSnackbar('Error sending command', { variant: 'error' });
        }
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    return (
        <>
            <Button className="btn-primary btn-sm" onClick={handleShow}>
                Comandos
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Comandos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Button variant="primary" onClick={() => handleApiRequest('{"nome":"' + Sessao + '","comando":"start"}', 'gerador/comando')}>
                            Start
                        </Button>
                        <Button variant="primary" onClick={() => handleApiRequest('{"nome":"' + Sessao + '","comando":"stop"}', 'gerador/comando')}>
                            Stop
                        </Button>
                        <Button variant="primary" onClick={() => handleApiRequest('{"nome":"' + Sessao + '","comando":"restart"}', 'gerador/comando')}>
                            Restart
                        </Button>
                        <Button variant="primary" onClick={() => handleApiRequest('{"nome":"' + Sessao + '","comando":"delete"}', 'gerador/comando')}>
                            Delete
                        </Button>
                        <Button variant="primary" onClick={() => handleApiRequest('{"nome":"' + Sessao + '","porta":' + Porta + '}', 'gerador/criar')}>
                            Recreate
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default function Mqtt(sessao) {
    return (
        <SnackbarProvider maxSnack={3}>
            <MqttModal sessao={sessao} />
        </SnackbarProvider>
    );
}
