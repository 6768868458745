import React, { useState } from "react";
import { InputGroup, FormControl } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { SnackbarProvider, useSnackbar } from "notistack";
import DBService from "./dbservice";

const Edit = (registro) => {

  const gravaRegistro = () => {
    console.log(regtemp.id)
    DBService.atualiza(regtemp.id, regtemp)
      .then(() => {
        outro()
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const { enqueueSnackbar } = useSnackbar();

  const outro = () => {
    handleClick("Seus dados foram atualizados", "success");
  };

  const handleClick = (dados, variant) => {
    enqueueSnackbar(dados, { variant });
  };

  const [regtemp, setRegtemp] = useState(registro.registro.registro.registro);

  const alteracaoManual = event => {
    const { name, value } = event.target;
    setRegtemp({ ...regtemp, [name]: value });
  };


  return (
    <div className="submit-form">
      <div>
        <Paper style={{ maxHeight: 600, overflow: 'auto', backgroundColor: '#1e2129', color: '#98a0b4' }}>
          <InputGroup className="mb-3 d-flex flex-row-reverse" ><i>{new Date(regtemp.criado.seconds * 1000).toLocaleString()}</i></InputGroup>
    <div className="form-group">
<InputGroup className="mb-3">
        <InputGroup.Text>Nome</InputGroup.Text>
            <FormControl
            type="text"
            id="nome"
            required
            value={regtemp.nome}
            onChange={alteracaoManual}
            name="nome" />
</InputGroup>
    </div>
    <div className="form-group">
<InputGroup className="mb-3">
        <InputGroup.Text>Valor</InputGroup.Text>
            <FormControl
            type="text"
            id="valor"
            required
            value={regtemp.valor}
            onChange={alteracaoManual}
            name="valor" />
</InputGroup>
    </div>
    <div className="form-group">
<InputGroup className="mb-3">
        <InputGroup.Text>Data</InputGroup.Text>
            <FormControl
            type="text"
            id="data"
            required
            value={regtemp.data}
            onChange={alteracaoManual}
            name="data" />
</InputGroup>
    </div>
        </Paper>
        <button onClick={gravaRegistro} className="btn btn-success btn-sm">
          Atualizar
        </button>
      </div>
    </div>
  );
};

export default function IntegrationNotistack(registro) {
  return (
    <SnackbarProvider maxSnack={3}>
      <Edit registro={registro} />
    </SnackbarProvider>
  );
}