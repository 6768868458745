import React, { useState } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import DBService from "./dbservice";

const Registro = (props) => {
  const estadoInicial = {
    key: null,
    uid: "",
    criado: "",
    script: "",

  };

  const [regtemp, setRegtemp] = useState(estadoInicial);
  const [mensagem, setMensagem] = useState("");
  const { registro } = props;

  if (regtemp.id !== registro.id) {
    setRegtemp(registro);
    setMensagem("");
  }

  const alteracaoManual = (event) => {
    const { name, value } = event.target;
    setRegtemp({ ...regtemp, [name]: value });
  };

  const atualizaRegistro = () => {
    const data = {
      uid: regtemp.uid,
      criado: regtemp.criado,
      script: regtemp.script,
    };
    DBService.atualiza(regtemp.id, data)
      .then(() => {
        setMensagem("Registro atualizado com exito!");
        handleClick();
        props.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deletaRegistro = () => {
    try {
      if (window.confirm("Você vai mesmo deletar isso?")) {
        DBService.remove(regtemp.id)
          .then(() => {
            props.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };


  return (
    <div>
      {regtemp ? (
        <div className="edit-form">
          <form>
            <InputGroup className="mb-3 d-flex flex-row-reverse" ><i>{new Date(regtemp.criado.seconds * 1000).toLocaleString()}</i></InputGroup>    <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Script</InputGroup.Text>
                <FormControl
                  as="textarea"
                  id="script"
                  required
                  value={regtemp.script}
                  onChange={alteracaoManual}
                  rows={20}
                  name="script" />
              </InputGroup>
            </div>
          </form>

          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={deletaRegistro}
          >
            Deletar
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={atualizaRegistro}
          >
            Atualizar
          </button>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Registro atualizado com exito!
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div>
          <br />
          <p>Clique no Registro...</p>
        </div>
      )}
    </div>
  );
};

export default Registro;
