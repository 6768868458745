import React, { useState } from "react";
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { SnackbarProvider, useSnackbar } from "notistack";
import DBService from "./dbservice";


const Add = (registro) => {
  
  const dados = registro.registro
  
  const estadoInicial = {
    uid: "",
    criado: "",
    nome: "",
    hora: "",
    comando: "",
    topic: "",
    mid:"",
  };

  const gravaRegistro = () => {
    var data = {
      uid: regtemp.uid,
      criado: new Date(),
      nome: dados.text,
      hora: regtemp.hora,
      comando: regtemp.comando,
      topic: dados.moduleId,
      mid: dados.id
    };
    DBService.criaRegistro(data)
      .then(() => {
        setEnviado(true);
        outro();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const { enqueueSnackbar } = useSnackbar();

  const outro = () => {
    handleClick("Seus dados foram gravados", "success");
  };

  const handleClick = (dados, variant) => {
    enqueueSnackbar(dados, { variant });
  };

  const [regtemp, setRegtemp] = useState(estadoInicial);
  const [enviado, setEnviado] = useState(false); //altera tela depois de enviar

  const alteracaoManual = event => {
    const { name, value } = event.target;
    setRegtemp({ ...regtemp, [name]: value });
  };

  const novoRegistro = () => {
    setRegtemp(estadoInicial);
    setEnviado(false);
  };

  return (
    <div className="submit-form">
      {enviado ? (
        <div>
          <h4>Seus dados foram gravados!</h4>
          <button className="btn btn-success btn-sm" onClick={novoRegistro}>
            Adicionar
          </button>
        </div>
      ) : (
        <div>
          <Paper style={{ maxHeight: 600, overflow: 'auto', backgroundColor: '#1e2129', color: '#98a0b4' }}>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Hora</InputGroup.Text>
                <Form.Control
                  type="time"
                  id="hora"
                  name="hora"
                  value={regtemp.hora}
                  onChange={alteracaoManual}
                  required
                />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Comando</InputGroup.Text>
                <Form.Select
                  id="comando"
                  value={regtemp.comando}
                  onChange={alteracaoManual}
                  name="comando"
                >
                  <option value="pulso">Pulso</option>
                  <option value="ligar">Ligar</option>
                  <option value="desligar">Desligar</option>
                </Form.Select>
              </InputGroup>
            </div>
      
          </Paper>
          <button onClick={gravaRegistro} className="btn btn-success btn-sm">
            Enviar
          </button>
        </div>
      )}
    </div>
  );
};

export default function IntegrationNotistack(registro) {

  return (
    <SnackbarProvider maxSnack={3}>
      <Add registro={registro.registro.registro}/>
    </SnackbarProvider>
  );
}