import React, { useState, useEffect } from "react";
import { FormControl } from 'react-bootstrap';
import { SnackbarProvider, useSnackbar } from "notistack";
import axios from 'axios';
import Erro from "../Erro/erro";
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase'
import { Tabs, Tab } from 'react-bootstrap';
import { Button } from "react-bootstrap"

function MensagemEmMassa(registro) {

    const id = registro.id.id.id_secao
    const [numeros, setNumeros] = useState("");
    const [mensagem, setMensagem] = useState("");
    const [enviando, setEnviando] = useState(false);
    const [delay, setDelay] = useState(3);
    const [mensagemVazia, setMensagemVazia] = useState(false);
    const [numerosVazio, setNumerosVazio] = useState(false);
    const [key, setKey] = useState('primeiraAba');

    const [file, setFile] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [error, setError] = useState(null);
    const [fileSize, setFileSize] = useState(null);
    var tipo = ""

    useEffect(() => {
        // Carregar os dados salvos ao inicializar
        async function carregarDados() {
            try {
                const docRef = doc(db, 'envioMassa', id); // Substitua pelos seus dados
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setNumeros(data.numeros);
                    setMensagem(data.mensagem);
                    setImagePath(data.imagePath);
                    setDelay(data.delay);
                }
            } catch (error) {
                console.error("Erro ao carregar dados:", error);
            }
        }
        carregarDados();
    }, []); // Executar apenas uma vez ao inicializar



    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        // Calcular o tamanho do arquivo em kilobytes
        const sizeInKB = selectedFile.size / 1024;
        setFileSize(sizeInKB.toFixed(2) + ' KB');
    };

    const handleUpload = () => {
        if (file) {
            const formData = new FormData();
            formData.append('image', file);

            axios.post('https://suporte.agteletronic.com.br/upload', formData)
                .then(response => {
                    setImagePath('https://suporte.agteletronic.com.br/imagem/' + response.data.imagePath);
                    setError(null);
                })
                .catch(error => {
                    setError('Erro ao fazer upload. Tamanho máximo permitido 1024 KB.');
                });
        } else {
            setError('Nenhum arquivo selecionado');

        }
    };

    const handleNumerosChange = (event) => {
        // Removendo espaços em branco e números repetidos
        const numerosSemEspacos = event.target.value.replace(/[^\d,]/g, "");
        const numerosUnicos = [...new Set(numerosSemEspacos.split(","))];
        const numerosUnicosString = numerosUnicos.join(",");
        setNumeros(numerosUnicosString);
        setNumerosVazio(false)
    };

    const handleEnviarTexto = () => {
     tipo = "texto";
     handleEnviarClick();
    }

    const handleEnviarMedia = () => {
        tipo = "media";
        handleEnviarClick();
       }

    const handleSalvarClick = async () => {
        try {
            const docRef = doc(db, 'envioMassa', id); // Substitua pelos seus dados
            await setDoc(docRef, {
                numeros,
                mensagem,
                imagePath,
                delay
            });
            alert("Dados gravados com sucesso!");
        } catch (error) {
            console.error("Erro ao salvar dados:", error);
        }
    };

    const handleMensagemChange = (event) => {
        setMensagem(event.target.value);
        setMensagemVazia(false)
    };

    const { enqueueSnackbar } = useSnackbar();

    const handleClick = (dados, variant) => {
        enqueueSnackbar(dados, { variant });
    };

    const handleEnviarClick = () => {

        if (numeros === "") {
            setNumerosVazio(true);
            return;
        }
        if (mensagem === "") {
            setMensagemVazia(true);
            return;
        }
        setEnviando(true);
        let numerosArray = normalizeNumeros(numeros).split(",");
        numerosArray = numerosArray.map((numero) => numero.trim());
        if (numerosArray[numerosArray.length - 1] === "") {
            numerosArray = numerosArray.slice(0, -1);
        }
        numerosArray = [...new Set(numerosArray)]; // remover números duplicados
        let contador = 0;

        const intervalId = setInterval(() => {

            if (contador < numerosArray.length) {

                if (tipo == "texto"){
                    handleFormSubmitTexto(numerosArray[contador])
                } else{
                    handleFormSubmitMedia(numerosArray[contador])
                }
                
                handleClick(`Enviando mensagem para ${numerosArray[contador]}`, "success");
                contador++;
            } else {
                setEnviando(false);
                clearInterval(intervalId);
                alert(`Envio em massa concluído! Mensagem enviada para ${numerosArray.length} número(s) de telefone.`);
            }
        }, delay * 1000);
    };

    const normalizeNumeros = (numeros) => {
        // Remove espaços entre os números
        numeros = numeros.replace(/\s/g, "");
        // Remove vírgulas extras
        numeros = numeros.replace(/,{2,}/g, ",");
        // Remove vírgula no final
        numeros = numeros.replace(/,$/, "");
        return numeros;
    };

    const handleFormSubmitTexto = (numero) => {
        var regtemp = {
            number: "55" + numero,
            message: mensagem,
            id: registro.id.id.id_secao,
            token: registro.id.id.token
        }

        axios({
            method: 'POST',
            url: 'https://api.agteletronic.com.br/send-message',
            data: regtemp
        })
            .then(function (response) {

            })
            .catch(function (response) {
                alert('Desculpe-nos, mas estamos fazendo uma manutenção temporária em nosso site. Por favor, tente novamente mais tarde. Obrigado pela sua compreensão.');
                Erro("Erro ao enviar mensagem no site.")
            });
    }

    const handleFormSubmitMedia = (numero) => {
        var regtemp = {
            number: "55" + numero,
            caption: mensagem,
            file: imagePath,
            id: registro.id.id.id_secao,
            token: registro.id.id.token
        }

        axios({
            method: 'POST',
            url: 'https://api.agteletronic.com.br/send-media',
            data: regtemp
        })
            .then(function (response) {

            })
            .catch(function (response) {
                alert('Desculpe-nos, mas estamos fazendo uma manutenção temporária em nosso site. Por favor, tente novamente mais tarde. Obrigado pela sua compreensão.');
                Erro("Erro ao enviar mensagem no site.")
            });
    }

    return (

        <div>
            <Tabs
                id="tabs-control"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="primeiraAba" title="Texto" >
                    {/* Conteúdo da primeira aba */}
                    <div className="form-group">
                        <label>
                            <span>{numeros === "" ? <div>Quantidade: 0</div> : <div>Quantidade: {numeros.split(",").length}</div>} </span>
                        </label>
                        <br />
                        <label>
                            Números de telefone (ddd + número, separados por vírgula):
                        </label>
                        <FormControl
                            as="textarea"
                            placeholder="números..."
                            id="números"
                            style={{ height: '200px' }}
                            required
                            value={numeros}
                            onChange={handleNumerosChange}
                            name="números" />
                        {numerosVazio && <span style={{ color: "red" }}>O número não pode estar vazio!</span>}
                    </div>
                    <label>
                        Mensagem:
                    </label>
                    <FormControl
                        as="textarea"
                        placeholder="mensagem..."
                        id="mensagem"
                        style={{ height: '200px' }}
                        required
                        value={mensagem}
                        onChange={handleMensagemChange}
                        name="números" />
                    {mensagemVazia && <span style={{ color: "red" }}>A mensagem não pode estar vazia!</span>}
                    <label>
                        Intervalo (segundos):
                        <FormControl
                            type="number"
                            min="3"
                            value={delay}
                            onChange={(e) => setDelay(e.target.value)} />
                    </label>
                    <br />
                    <button disabled={enviando} onClick={handleEnviarTexto} className="btn btn-info btn-sm pull-right">
                        Enviar
                    </button>
                    <button onClick={handleSalvarClick} className="btn btn-success btn-sm pull-left">Gravar Informações</button>
                    <br />
                    <div />

                </Tab>
                <Tab eventKey="segundaAba" title="Imagem">
                    {/* Conteúdo da segunda aba */}
                    <div>

                        <div className="form-group">
                            <label>
                                <span>{numeros === "" ? <div>Quantidade: 0</div> : <div>Quantidade: {numeros.split(",").length}</div>} </span>
                            </label>
                            <br />
                            <label>
                                Números de telefone (ddd + número, separados por vírgula):
                            </label>
                            <FormControl
                                as="textarea"
                                placeholder="números..."
                                id="números"
                                style={{ height: '200px' }}
                                required
                                value={numeros}
                                onChange={handleNumerosChange}
                                name="números" />
                            {numerosVazio && <span style={{ color: "red" }}>O número não pode estar vazio!</span>}
                        </div>
                        <div class="input-group mb-3">
                            <input type="file" onChange={handleFileChange} />
                            <Button onClick={handleUpload}>Carregar</Button>
                        </div>

                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {fileSize &&
                            (<p>Tamanho do Arquivo: {fileSize}</p>)
                        }
                        {imagePath && (
                            <div>
                                <img src={imagePath} alt={imagePath} className="image-preview" />
                            </div>
                        )}
                        <label>
                            Legenda:
                        </label>
                        <FormControl
                            as="textarea"
                            placeholder="mensagem..."
                            id="mensagem"
                            style={{ height: '200px' }}
                            required
                            value={mensagem}
                            onChange={handleMensagemChange}
                            name="números" />
                        {mensagemVazia && <span style={{ color: "red" }}>A mensagem não pode estar vazia!</span>}
                        <label>
                            Intervalo (segundos):
                            <FormControl
                                type="number"
                                min="3"
                                value={delay}
                                onChange={(e) => setDelay(e.target.value)} />
                        </label>
                        <br />
                        <button disabled={enviando} onClick={handleEnviarMedia} className="btn btn-info btn-sm pull-right">
                            Enviar
                        </button>
                        <button onClick={handleSalvarClick} className="btn btn-success btn-sm pull-left">Gravar Informações</button>
                        <br />
                        <div />

                    </div>

                </Tab>
            </Tabs>

        </div>

    );
}


export default function IntegrationNotistack(registro) {
    return (
        <SnackbarProvider maxSnack={3}>
            <MensagemEmMassa id={registro} />
        </SnackbarProvider>
    );
}