import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ListadeResgistros from "./listaderegistros";
import AddModal from "./modalAdd";
import { Card } from "antd";
import Tooltip from "@material-ui/core/Tooltip";
const { Meta } = Card;

export default function TodoModal(currentUser) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Card
        onClick={handleShow}
        hoverable
        style={{
          width: 170,
          marginleft: 'auto',
          marginright: 'auto',
        }}
        cover={
          <div className="center2" >
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-card-checklist" viewBox="0 0 16 16">
              <g color="#556278">
                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
              </g>
            </svg>
          </div>
        }
      >
        <Meta
          title="Lista de Tarefas"
          description="Lista contendo as modificações ou criações a serem feitas no api do zap"
          onClick={handleShow}
        />
      </Card>
      <Modal className="special_modal" size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Lista de Tarefas
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListadeResgistros usuario={currentUser} />
        </Modal.Body>
        <Modal.Footer>
          <AddModal/>
          <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
