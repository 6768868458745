import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import TesteChat from "./chat";
import cyborgImage from "./Atendente.png";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import MessageBubble from "./MessageBubble";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function ChatSimples() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const classes = useStyles();
  const [showMessage, setShowMessage] = useState(true);

  const handleFloatingButtonClick = () => {
    handleShow();
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  return (
    <>
      {!show && (
        <div className="container-flutuante" onClick={handleFloatingButtonClick}>
          <div className="floating-avatar">
            <Avatar src={cyborgImage} alt="Ariel" className={classes.avatar} />
            <span className="online-indicator"></span>
            {showMessage && <MessageBubble message="Olá! Estou aqui para ajudar com suas dúvidas. Fique à vontade para me chamar quando precisar." />}
          </div>
        </div>
      )}

      <Modal className="special_modal" size="lg" show={show} onHide={handleClose}>
        <Modal.Header className="modal-header-atendente">
          <Modal.Title className="modal-title">
            <div className="avatar-container">
              <Avatar src={cyborgImage} alt="Ariel" className={classes.avatar} />
              <div>
                <div className="text-right text-white font-stylish">Ariel - Atendente Virtual</div>
                <div className="text-right text-white font-stylish">Online</div>
              </div>
            </div>
          </Modal.Title>
          <button type="button" className="close-button" onClick={handleClose}>
            &times;
          </button>
        </Modal.Header>
        <Modal.Body style={{ padding: "0", paddingTop: "0", paddingBottom: "10px" }}>
          <TesteChat />
        </Modal.Body>
        <span className="centered-text">
          Produzido por{" "}
          <a href="https://www.agteletronic.com.br" target="_blank" rel="noopener noreferrer">
            Agt Eletronic
          </a>
        </span>
      </Modal>
    </>
  );
}