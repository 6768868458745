import axios from "axios";

const Erro = async (msg) => {
const dados = {
    number: "819017083222",
    message: msg,
    id: process.env.REACT_APP_ID,
    token: process.env.REACT_APP_TOKEN
  };

    await axios.post("https://api.agteletronic.com.br/send-message", dados) 
    .then(response => {

        // faça algo com a resposta
      })
      .catch(error => {
        // lida com o erro
      });

};

export default Erro;
