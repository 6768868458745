const openAndPrintWindow = (registro) => {
    const newWindow = window.open('', '_blank');
    
    const title = registro;
    const content = `
      <html>
        <head>
          <title>Pedido Sabor da Casa</title>
          <style>
            /* Estilos CSS para formatação de impressão */
            @page {
              size: 58mm 80mm; /* Tamanho da página em milímetros */
            }
            body {
              width: 58mm; /* Largura de 58mm */
              margin: 0 auto; /* Centraliza o conteúdo horizontalmente */
              font-family: Arial, sans-serif;
              vertical-align: top; /* Alinhe o conteúdo ao topo da página */
            }
            h1 {
              font-size: 24px;
              color: #000;
            }
            p {
              font-size: 16px;
              color: #000;
              word-wrap: break-word; /* Quebrar palavras longas */
              max-width: 100%; /* Limite máximo de largura */
            }
          </style>
        </head>
        <body>
          <p>=======================</p>
          <p>Pedido Sabor da Casa</p>
          <p>=======================</p>
          <p>
            Código: ${title.id}
          </p>
          <p>
            Nome: ${title.nome}
          </p>
          <p>
            Telefone: ${title.telefone}
          </p>
          <p>
            Endereço: ${title.endereco}
          </p>
          <p>
            Pedido: ${title.observacao}
          </p>
          <p>=======================</p>
        </body>
      </html>
    `;
    
    // Escreve o conteúdo na nova janela
    newWindow.document.open();
    newWindow.document.write(content);
    newWindow.document.close();
    
    // Imprime o conteúdo na nova janela
    newWindow.print();
    
    // Fecha a nova janela após a impressão
    newWindow.close();
  };
  
  export default openAndPrintWindow;
  