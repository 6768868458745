import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ListadeResgistros from "./listaderegistros";
import AddModal from "./modalAdd";
import { Card } from "antd";
import Tooltip from "@material-ui/core/Tooltip";
const { Meta } = Card;


export default function PerSinModal() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Card
        onClick={handleShow}
        hoverable
        style={{
          width: 170,
          marginleft: 'auto',
          marginright: 'auto',
        }}
        cover={
          <div className="center2" >
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-question-square" viewBox="0 0 16 16">
              <g color="#556278">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
              </g>
            </svg>
          </div>
        }
      >
        <Meta
          title="Perguntas Sincronizadas"
          description="Cadastro e editor de perguntas para o assistente virtual."
          onClick={handleShow}
        />
      </Card>
      <Modal className="special_modal" size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Perguntas Sincronizadas
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListadeResgistros />
        </Modal.Body>
        <Modal.Footer>
          <AddModal />
          <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
