import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Dlt from "./index";
import Tooltip from "@material-ui/core/Tooltip";

export default function DltModal(id_secao) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <Tooltip title="Deletar sessão">
      <Button className="btn btn-danger btn-sm" onClick={handleShow} >
      <i class="fa fa-trash" aria-hidden="true"></i>
          </Button>
          </Tooltip>
      <Modal className="special_modal" size="sm" show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            Deletar sessão
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dlt id={id_secao}/>
        </Modal.Body>
        <Modal.Footer>
        <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
