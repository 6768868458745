import React, { useState, useEffect } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import Paper from "@material-ui/core/Paper";
import { Table, Button } from 'react-bootstrap';
import Tooltip from "@material-ui/core/Tooltip";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/pt-br';
import { db } from "../../../firebase";
import DBService from "./dbservice";
import Modaledit from "./modalEdit";
import openAndPrintWindow from "./impressao";
import ModalObservacao from "./ModalObservacao";
import { format } from 'date-fns';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR'; // Importe a localização específica do Brasil
registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR');
const colecao = "pedidos";

const ListadeResgistrosTabela = (currentUser) => {
  const [regtemp] = useState(currentUser.usuario.usuario.uid);
  //const [regtemp] = useState(currentUser.usuario.usuario.usuario.uid);
  const [registros, setRegistros] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with the current date
  const [countPendente, setCountPendente] = useState(0);
  const [countConcluido, setCountConcluido] = useState(0);
  const tableHeaderStyle = {
    backgroundColor: 'black', // Change the background color to suit your design
    color: 'white', // Change the text color to white or another suitable color
  };
  const [filter, setFilter] = useState("All"); // Initialize the filter state
  const [showObservacao, setShowObservacao] = useState(false);
  const [observacaoSelecionada, setObservacaoSelecionada] = useState('');

  const handleObservacaoClick = (observacao) => {
    setObservacaoSelecionada(observacao);
    setShowObservacao(true);
  };

  const handleCloseObservacao = () => {
    setShowObservacao(false);
  };


  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const renderRecords = () => {
    if (filter === "All") {
      return registros;
    } else if (filter === "Pendente") {
      return registros.filter((registro) => registro.estado === "Pendente");
    } else if (filter === "Concluído") {
      return registros.filter((registro) => registro.estado === "Concluído");
    }
  };

  useEffect(() => {
    const usersCollectionRef = collection(db, colecao);
    let usersQuery = query(usersCollectionRef);

    if (selectedDate) {
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);

      usersQuery = query(usersCollectionRef, where("dataEvento", ">=", startOfDay), where("dataEvento", "<=", endOfDay));
    }

    const unsub = onSnapshot(usersQuery, (querySnapshot) => {
      const sortedRegistros = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      sortedRegistros.sort((a, b) => a.dataEvento.toMillis() - b.dataEvento.toMillis());
      setRegistros(sortedRegistros);

      // Calculate the counts for "Pendente" and "Concluído" within the onSnapshot callback
      const pendenteCount = sortedRegistros.filter((registro) => registro.estado === "Pendente").length;
      const concluidoCount = sortedRegistros.filter((registro) => registro.estado === "Concluído").length;

      setCountPendente(pendenteCount);
      setCountConcluido(concluidoCount);
    });

    return unsub;
  }, [selectedDate]);

  const deletaRegistro = (registro) => {
    if (window.confirm("Tem certeza de que deseja excluir este pedido?")) {
      DBService.remove(registro)
        .then(() => {
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const openWhatsApp = (telefone) => {
    const numero = telefone.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    const link = `https://api.whatsapp.com/send?phone=55${numero}`;
    window.open(link, "_blank");
  };

  const openGoogleMaps = (endereco) => {
    const enderecoFormatado = endereco.replace(/\s/g, '+'); // Substitui espaços por '+' para o formato da URL
    const link = `https://www.google.com/maps/search/?api=1&query=${enderecoFormatado}`;
    window.open(link, "_blank");
  };

  return (
    <div>
      <div>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          placeholderText="Selecione uma data"
          dateFormat="dd/MM/yyyy"
          locale="pt-BR" // Defina a localização do DatePicker para o Brasil
        />
        {/* Use Bootstrap buttons for filtering */}
        <Button onClick={() => handleFilterChange("All")} variant="primary">Todos</Button>
        <Button onClick={() => handleFilterChange("Pendente")} variant="primary">Pendentes</Button>
        <Button onClick={() => handleFilterChange("Concluído")} variant="primary">Concluídos</Button>
      </div>
      {registros.length === 0 ? (
        <div>
          <div class="alert alert-warning" role="alert">
            Ainda não temos nenhum registro cadastrado, clique em "+" para cadastrar.
          </div>
        </div>
      ) : (
        <div>
          <div className="card mb-4">
            <div style={{ width: '100%' }}>
              <ul className="list-group"><Paper style={{ maxHeight: 400, overflow: 'auto' }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Telefone</th>
                      <th>Endereco</th>
                      <th>Pedido</th>
                      <th>Data e Hora</th>
                      <th>Estado</th>
                      <th>Comandos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderRecords().map((registro) => (
                      <tr key={registro.id}>
                        <td>{registro.nome}</td>
                        <td>
                          <a href="#" onClick={() => openWhatsApp(registro.telefone)}>
                            {registro.telefone}
                          </a>
                        </td>
                        <td>
                          <a href="#" onClick={() => openGoogleMaps(registro.endereco)}>
                            {registro.endereco}
                          </a>
                        </td>
                        <td>
                          <a href="#" onClick={() => handleObservacaoClick(registro.observacao)}>
                            Ver Pedido
                          </a>
                        </td>
                        <td>{format(registro.dataEvento.toDate(), 'dd/MM/yyyy HH:mm')}</td>
                        <td>{registro.estado}</td>
                        <td>
                          <Tooltip title="Deletar">
                            <button onClick={() => deletaRegistro(registro.id)} className="btn btn-danger btn-sm">
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </Tooltip>
                          <Modaledit registro={registro} />

                          <button onClick={() => openAndPrintWindow(registro)} className="btn btn-success btn-sm">
                            <i class="fa fa-print" aria-hidden="true"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Paper>
              </ul>
            </div>
          </div>
        </div>
      )}
      <div>
        <div>
          <table className="table table-striped table-bordered table-hover">
            <thead style={tableHeaderStyle}>
              <tr>
                <th>Descrição</th>
                <th>Quantidade</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td >Total de Pedidos</td>
                <td>{registros.length}</td>
              </tr>
              <tr>
                <td>Pedidos Pendentes</td>
                <td>{countPendente}</td>
              </tr>
              <tr>
                <td>Pedidos Concluídos</td>
                <td>{countConcluido}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ModalObservacao
        show={showObservacao}
        handleClose={handleCloseObservacao}
        observacao={observacaoSelecionada}
      />
    </div>
  );
}
export default ListadeResgistrosTabela;
