import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const AddUser = () => {
  const [pergunta, setPergunta] = useState("");
  const [resposta, setResposta] = useState("");
  const [sessao, setSessao] = useState("");
  const history = useHistory();

  const saveUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:5000/users", {
        pergunta,
        resposta,
        sessao,
      });
      history.push("/lista");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="columns mt-5 is-centered">
      <div className="column is-half">
        <form onSubmit={saveUser}>
          <div className="field">
            <label className="label">Pergunta</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={pergunta}
                onChange={(e) => setPergunta(e.target.value)}
                placeholder="Pergunta"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Resposta</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={resposta}
                onChange={(e) => setResposta(e.target.value)}
                placeholder="Resposta"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Sessão</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  value={sessao}
                  onChange={(e) => setSessao(e.target.value)}
                >
                  <option value="bot-zdg">bot-zdg</option>
                  <option value="server">server</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <button type="submit" className="button is-success">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
