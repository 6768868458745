import {db} from "../../../firebase";
import { collection, addDoc, doc, deleteDoc, updateDoc } from "firebase/firestore"

const colecao = "Anotacoes";

const db2 = collection(db, colecao );

const pegaTudo = () => {
  return db2;
};

const criaRegistro = (data) => {
  return addDoc(db2, data);
};

const atualiza = (id, value) => {
  return updateDoc(doc(db, colecao, id), value);
};

const remove = (id) => {
  return deleteDoc(doc(db, colecao, id));
};


const DBService = {
  pegaTudo,
  criaRegistro,
  atualiza,
  remove,
};

export default DBService;