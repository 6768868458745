import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import AddModal from "./modalAdd";
import ListadeResgistrosTabela from "./tabela";

const titulo = "Dividendos";

export default function TabelaModalDividendos(stock) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
     <Button
        onClick={handleShow}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0",
        }}
      >
        <i className="fa fa-money fa-2x" style={{ color: "#556278" }}></i>
      </Button>
      <Modal className="special_modal" size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {titulo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListadeResgistrosTabela stock={stock}/>
        </Modal.Body>
        <Modal.Footer>
          <AddModal stock={stock}  />
          <Button variant="secondary btn-sm" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
