import React, { useEffect, useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import axios from 'axios';

export default function ModalAtendimento(registro) {

  const [answer, setAnswer] = useState('');
  const [vartranscript, setVartranscript] = useState('');
  const recognitionRef = useRef(null);
  const audioRef = useRef(null);
  const isRestartingRef = useRef(false);
  const [show, setShow] = useState(false);
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(false); // Estado para controlar o microfone

  const handleClose = () => {
    setShow(false);
    setIsMicrophoneEnabled(false);
    recognitionRef.current.stop();
  };

  const handleShow = () => {
    setShow(true);
    setIsMicrophoneEnabled(true);
    recognitionRef.current.start();
  };

  const handleSubmit = async (transcript) => {
    const responseText = await chat(transcript);
    setAnswer(responseText);

    // Enviar a resposta para o servidor Node.js
    axios
      .post('https://agteletronic.com.br/convert', { texto: responseText }, { responseType: 'blob' })
      .then((response) => {
        const audioUrl = URL.createObjectURL(response.data);
        recognitionRef.current.stop();
        const audio = new Audio(audioUrl);
        audioRef.current = audio;
        audio.play();
        audio.onended = () => {
          if (!isRestartingRef.current) {
            recognitionRef.current.start();
          }
        };
      })
      .catch((error) => {
        console.error('Erro ao reproduzir áudio:', error);
        if (!isRestartingRef.current) {
          recognitionRef.current.start();
        }
      });
  };

  const createSpeechRecognition = () => {
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.continuous = true;

    recognition.onresult = (event) => {
      const transcript = event.results[event.results.length - 1][0].transcript;
      handleSubmit(transcript);
      setVartranscript(transcript);
    };

    recognition.onerror = (event) => {
      console.error('Erro de reconhecimento de fala:', event.error);
      recognitionRef.current.stop();
      if (!isRestartingRef.current) {
        isRestartingRef.current = true;
        setTimeout(() => {
          isRestartingRef.current = false;
          recognitionRef.current.start();
        }, 100);
      }
    };

    return recognition;
  };

  useEffect(() => {
    recognitionRef.current = createSpeechRecognition();
    if (isMicrophoneEnabled) {
      recognitionRef.current.start();
    }

    return () => {
      recognitionRef.current.stop();
    };
  }, [isMicrophoneEnabled]);

  const chat = async (transcript) => {
    console.log(transcript);
    try {
      const response = await axios.post('https://agteletronic.com.br/chat', {
        message: transcript,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return (
    <>
      <Tooltip title="Atendimento">
        <button onClick={handleShow} className="btn btn-primary btn-sm">
          Atendimento por Voz
        </button>
      </Tooltip>
      <Modal
        size="lg"
        centered
        className="special_modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Atendimento por Voz
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {vartranscript && (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <img
      src="user.png" // Substitua pelo caminho ou URL da sua imagem
      alt="Descrição da imagem"
      style={{
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        marginRight: '10px',
      }} // Ajuste o estilo conforme necessário
    />
    <div>
      <h4>Pergunta:</h4>
      <p>{vartranscript}</p>
    </div>
  </div>
)}
{answer && (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <img
      src="cyborg.png" // Substitua pelo caminho ou URL da sua imagem
      alt="Descrição da imagem"
      style={{
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        marginRight: '10px',
      }} // Ajuste o estilo conforme necessário
    />
    <div>
      <h4>Resposta:</h4>
      <p>{answer}</p>
    </div>
  </div>
)}

          <audio ref={audioRef} />

        </Modal.Body>
        <Modal.Footer>
          <Tooltip title="Fechar">
            <Button className="btn-secondary btn-sm" onClick={handleClose}>
              <i class="fa fa-times" aria-hidden="true"></i>
            </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}

