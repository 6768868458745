import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { db } from "../../../firebase";
import { collection, getDocs } from 'firebase/firestore';

const TesteChat = () => {
  const [messages, setMessages] = useState({});
  const [newMessage, setNewMessage] = useState('');
  const [registros, setRegistros] = useState([]);
  const [valorSelecionado, setValorSelecionado] = useState('');
  const [descricaoSelecionada, setDescricaoSelecionada] = useState('');
  const [exemploSelecionado, setExemploSelecionado] = useState('');
  const [configuracaoSelecionada, setConfiguracaoSelecionada] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isConversationCleared, setIsConversationCleared] = useState(false);
  const [showClearAlert, setShowClearAlert] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const scrollToBottom = () => {
    const chatBox = document.getElementById('chat-box');
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
    carregarColecao();

    const savedMessages = localStorage.getItem('chatMessages');
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  async function carregarColecao() {
    const querySnapshot = await getDocs(collection(db, 'GeradorDePrompt'));
    const documentos = querySnapshot.docs.map(doc => {
      const { nome, descricao, exemplo } = doc.data();
      return { nome, descricao, exemplo };
    });
    setRegistros(documentos);
  }

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    if (newMessage !== '' && configuracaoSelecionada) {
      setMessages((prevMessages) => {
        const selectedMessages = { ...prevMessages };
        selectedMessages[valorSelecionado] = [...(selectedMessages[valorSelecionado] || []), { text: newMessage, sender: 'user' }];
        return { ...selectedMessages };
      });
      setNewMessage('');

      try {
        const response = await axios.post('https://suporte.agteletronic.com.br/chathistorico', {
          message: newMessage,
          informacoes: descricaoSelecionada,
        });

        const respostaDoServidor = response.data.response.content;

        setMessages((prevMessages) => {
          const selectedMessages = { ...prevMessages };
          selectedMessages[valorSelecionado] = [...(selectedMessages[valorSelecionado] || []), { text: respostaDoServidor, sender: 'bot' }];
          return { ...selectedMessages };
        });
      } catch (error) {
        console.error('Erro ao obter resposta da API:', error);
      }
    } else if (!configuracaoSelecionada) {
      setShowAlert(true);
    }

    setIsConversationCleared(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleMessageSubmit(e);
    }
  };

  const handleConfiguracaoSelect = (e) => {
    const selecionado = registros.find(registro => registro.nome === e.target.value);
    setValorSelecionado(e.target.value);
    setDescricaoSelecionada(selecionado.descricao);
    setExemploSelecionado(selecionado.exemplo);
    setConfiguracaoSelecionada(true);
  };

  const clearConversation = () => {
    setMessages((prevMessages) => {
      const updatedMessages = { ...prevMessages };
      updatedMessages[valorSelecionado] = [];
      return { ...updatedMessages };
    });
    setIsConversationCleared(true);
    setShowClearAlert(true);
    setTimeout(() => {
      setShowClearAlert(false);
    }, 3000);
  };

  const handleCopy = () => {
    const content = selectedMessages.map((message) => `${message.sender}: ${message.text}`).join('\n');
    navigator.clipboard.writeText(content)
      .then(() => setIsCopied(true))
      .catch((error) => console.error('Erro ao copiar para área de transferência:', error));
  };

  const selectedMessages = messages[valorSelecionado] || [];

  const downloadChatHistory = () => {
    const content = selectedMessages.map((message) => `${message.sender}: ${message.text}`).join('\n');
    const element = document.createElement('a');
    const file = new Blob([content], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'chat_history.txt';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Container className="mt-4">
      <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        Selecione uma configuração antes de enviar a mensagem.
      </Alert>
      <Alert variant="success" show={showClearAlert} onClose={() => setShowClearAlert(false)} dismissible>
        Conversa limpa com sucesso!
      </Alert>
      <Alert variant="success" show={isCopied} onClose={() => setIsCopied(false)} dismissible>
        Conversa copiada com sucesso!
      </Alert>
      <Form.Select
        style={{ width: '500px' }}
        value={valorSelecionado}
        onChange={handleConfiguracaoSelect}
      >
        <option value="">Selecione uma configuração</option>
        {registros.map((registro, index) => (
          <option key={index} value={registro.nome}>
            {registro.nome}
          </option>
        ))}
      </Form.Select>
      <br/>
      <div className="chat-box" id="chat-box">
        {selectedMessages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.sender === 'user' ? 'user' : 'bot'}`}
          >
            {message.text}
          </div>
        ))}
      </div>
      <Button variant="secondary" onClick={clearConversation}>
        Limpar Conversa
      </Button>
      <Button variant="secondary" onClick={downloadChatHistory}>
        Baixar Histórico
      </Button>
      <Button variant="secondary" onClick={handleCopy}>
        Copiar Conversa
      </Button>
      <Form onSubmit={handleMessageSubmit}>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Digite sua mensagem..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Enviar
        </Button>
      </Form>
    </Container>
  );
};

export default TesteChat;
