import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import AddModal from "./modalAdd";
import ListadeResgistrosTabela from "./tabela";
import Tooltip from "@material-ui/core/Tooltip";


const titulo = "Hora Programada";

export default function AutomacaoTabelaModal(registro) {
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dados = registro.registro
 

  return (
    <>
      <Tooltip title="Hora Programada ">
        <button onClick={handleShow} className="btn btn-primary btn-sm">
          <i class="fa fa-clock-o" aria-hidden="true"></i>
        </button>
      </Tooltip>
      <Modal className="special_modal" size="sm" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {titulo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListadeResgistrosTabela registro={dados} />
        </Modal.Body>
        <Modal.Footer>
          <AddModal registro={dados}/>
          <Button variant="secondary btn-sm" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
