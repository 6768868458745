import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import AddModal from "./modalAdd";
import ListadeResgistros from "./listaderegistros";
import { ListGroup } from 'react-bootstrap';

export default function RegistroModal2() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

        <ListGroup.Item onClick={handleShow}>Gerador de Prompt</ListGroup.Item>

      <Modal className="special_modal" size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Gerador de Prompt
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListadeResgistros />
        </Modal.Body>
        <Modal.Footer>
          <AddModal />
          <Button variant="secondary btn-sm" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
