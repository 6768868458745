import React, { useState } from "react";
import { Button, Container, Form, Alert } from "react-bootstrap";
import { SnackbarProvider, useSnackbar } from "notistack";


function App() {
  const [word, setWord] = useState("");
  const [newWord, setNewWord] = useState("");
  const [text, setText] = useState("");
  const [result, setResult] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleWordChange = (e) => {
    setWord(e.target.value);
  };

  const handleNewWordChange = (e) => {
    setNewWord(e.target.value);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleCopyResult = () => {
    navigator.clipboard.writeText(result);
    enqueueSnackbar("Texto copiado para a área de transferência!", {
      variant: "success",
    });
  };

  const handleReplace = () => {
    const regex = new RegExp(word, "gi");
    const updatedText = text.replace(regex, newWord);
    setResult(updatedText);
  };

  return (
    <Container>
      <Alert variant="info">
        Para substituir todas as ocorrências da palavra, preencha o campo "Palavra"
        e "Nova palavra" e clique em "Substituir". O resultado será exibido abaixo.
      </Alert>
      <Form>
        <Form.Group controlId="word">
          <Form.Label>Palavra:</Form.Label>
          <Form.Control type="text" value={word} onChange={handleWordChange} />
        </Form.Group>
        <Form.Group controlId="newWord">
          <Form.Label>Nova Palavra:</Form.Label>
          <Form.Control
            type="text"
            value={newWord}
            onChange={handleNewWordChange}
          />
        </Form.Group>
        <Form.Group controlId="text">
          <Form.Label>Texto:</Form.Label>
          <Form.Control as="textarea" rows={5} value={text} onChange={handleTextChange} />
        </Form.Group>
      </Form>
      <Button variant="primary" onClick={handleReplace}>
        Substituir
      </Button>
      <hr />
      <Form.Group controlId="result">
        <Form.Label>Resultado:</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          value={result}
          readOnly
        />
      </Form.Group>
      <Button variant="secondary" onClick={handleCopyResult}>
        Copiar Resultado
      </Button>
    </Container>
  );
}

function SubPalavras() {
  return (
    <SnackbarProvider maxSnack={3}>
      <App />
    </SnackbarProvider>
  );
}

export default SubPalavras;
