import React, { useState, useEffect } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import Paper from "@material-ui/core/Paper";
import { Table } from 'react-bootstrap';
import Tooltip from "@material-ui/core/Tooltip";
import 'moment/locale/pt-br';
import { db } from "../../../firebase";
import Modaledit from "./modalEdit";
import DltModal from "../../Funcoes/Delete/modal";
import moment from 'moment';
import 'moment/locale/pt-br';
import FaturaModal from "../../Funcoes/ListarFaturas/Modal";
import RstModal from "../../Funcoes/Reset/modal";
import FormularioModal2 from "../../Funcoes/FormularioChatv2/FormularioModal";
import MsgModal from "../../Funcoes/Mensagem/modal";
import EnvioMassaModal from "../../Funcoes/Massa/modal";
import Mqtt from "../../Funcoes/ComandosMqtt/mqtt"
const colecao = "secao";

const ListadeResgistrosTabela = (currentUser) => {

  const [regtemp] = useState(currentUser.usuario.usuario.usuario.uid);
  const [registros, setRegistros] = useState([])

  useEffect(() => {
    const usersCollectionRef = query(collection(db, colecao));
    const unsub = onSnapshot(usersCollectionRef, (querySnapshot) => {
      setRegistros(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
    return unsub;
  }, []);

  return (
    <div>
      {registros.length === 0 ? <div><div class="alert alert-warning" role="alert">
        Ainda não temos nenhum registro cadastrado, clique em "+" para cadastrar.
      </div></div> : <div>
        <div className="card mb-4">
          <div style={{ width: '100%' }}>
            <ul className="list-group"><Paper style={{ maxHeight: 400, overflow: 'auto' }}>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Nomesecao</th>
                    <th>Id_secao</th>
                    <th>Token</th>
                    <th>Tipo</th>
                    <th>Estado</th>
                    <th>Pagamento</th>
                    <th>Porta</th>
                    <th>Validade</th>
                    <th>Valor</th>
                    <th>Comandos</th>
                    <th>Funções</th>
                  </tr>
                </thead>
                <tbody>
                  {registros.map((registro) => (
                    <tr>
                      <td>{registro.nomesecao}</td>
                      <td>{registro.id_secao}</td>
                      <td>{registro.token}</td>
                      <td>{registro.tipo}</td>
                      <td>{registro.estado}</td>
                      <td>{registro.pagamento}</td>
                      <td>{registro.porta}</td>
                      <td>{moment(registro.validade.toDate().toString()).format('L')}</td>
                      <td>{registro.valor}</td>
                      <td>
                        <Modaledit registro={registro} />
                        <FaturaModal id={registro.id_secao} />
                        <Tooltip title="Deletar"><DltModal id={registro} /></Tooltip>
                      {registro.pagamento === "Pago" || registro.pagamento === "Teste" ? <RstModal id={registro} /> : <div></div>}
                      {registro.tipo === "robo" ? <Tooltip title="Assistente"><FormularioModal2 id={registro.id_secao}/>
                      </Tooltip> : <div></div>}
                      <Mqtt sessao ={registro} />
                      </td>
                      <td>
                        {registro.estado === "Autenticado" ? <MsgModal id={registro} /> : <div></div>}
                        {registro.estado === "Autenticado" ? <EnvioMassaModal id={registro} /> : <div></div>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Paper>
            </ul>
          </div>
        </div>
      </div>}
    </div>
  );
}
export default ListadeResgistrosTabela;