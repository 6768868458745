import React, { useState } from "react";
import { InputGroup, FormControl } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { SnackbarProvider, useSnackbar } from "notistack";
import DBService from "./dbservice";

const Edit = (registro) => {

  const gravaRegistro = () => {
    DBService.atualiza(regtemp.id, regtemp)
      .then(() => {
        outro()
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const { enqueueSnackbar } = useSnackbar();

  const outro = () => {
    handleClick("Seus dados foram atualizados", "success");
  };

  const handleClick = (dados, variant) => {
    enqueueSnackbar(dados, { variant });
  };

  const [regtemp, setRegtemp] = useState(registro.registro.registro.registro);

  const alteracaoManual = event => {
    const { name, value } = event.target;
    setRegtemp({ ...regtemp, [name]: value });
  };


  return (
    <div className="submit-form">
      <div>
        <Paper style={{ maxHeight: 600, overflow: 'auto', backgroundColor: '#1e2129', color: '#98a0b4' }}>

          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Nome</InputGroup.Text>
              <FormControl
                type="text"
                id="nome"
                required
                value={regtemp.nome}
                onChange={alteracaoManual}
                name="nome" />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Telefone</InputGroup.Text>
              <FormControl
                type="text"
                id="telefone"
                required
                value={regtemp.telefone}
                onChange={alteracaoManual}
                name="telefone" />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Endereco</InputGroup.Text>
              <FormControl
                type="text"
                id="endereco"
                required
                value={regtemp.endereco}
                onChange={alteracaoManual}
                name="endereco" />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Pedido</InputGroup.Text>
              <FormControl
                type="text"
                id="observacao"
                required
                value={regtemp.observacao}
                onChange={alteracaoManual}
                name="observacao" />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Dataevento</InputGroup.Text>
              <FormControl
                type="text"
                id="dataEvento"
                required
                value={regtemp.dataEvento.toDate().toLocaleString()}
                onChange={alteracaoManual}
                name="dataEvento"
                disabled
              />
            </InputGroup>
          </div>
          <div className="form-group">
          <InputGroup className="mb-3">
          <InputGroup.Text>Estado</InputGroup.Text>
            <select
              id="estadoSelect"
              value={regtemp.estado}
              onChange={alteracaoManual}
              name="estado"
              className="form-control"
            >
              <option value="Pendente">Pendente</option>
              <option value="Concluído">Concluído</option>
            </select>
            </InputGroup>
          </div>
        </Paper>
        <button onClick={gravaRegistro} className="btn btn-success btn-sm">
          Atualizar
        </button>
      </div>
    </div>
  );
};

export default function IntegrationNotistack(registro) {
  return (
    <SnackbarProvider maxSnack={3}>
      <Edit registro={registro} />
    </SnackbarProvider>
  );
}