import React, { useState } from "react";
import { InputGroup, FormControl } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { SnackbarProvider, useSnackbar } from "notistack";
import DBService from "./dbservice";

const Edit = (registro) => {

  const gravaRegistro = () => {
    console.log(regtemp.id)
    DBService.atualiza(regtemp.id, regtemp)
      .then(() => {
        outro()
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const { enqueueSnackbar } = useSnackbar();

  const outro = () => {
    handleClick("Seus dados foram atualizados", "success");
  };

  const handleClick = (dados, variant) => {
    enqueueSnackbar(dados, { variant });
  };

  const [regtemp, setRegtemp] = useState(registro.registro.registro.registro);
  const [horalocal, setHoralocal] = useState({"hora" : new Date(regtemp.validade.seconds * 1000).toLocaleString()});

  const alteracaoManual = event => {
    const { name, value } = event.target;
    setRegtemp({ ...regtemp, [name]: value });
    console.log(horalocal.hora)
  };


  return (
    <div className="submit-form">
      <div>
        <Paper style={{ maxHeight: 600, overflow: 'auto', backgroundColor: '#1e2129', color: '#98a0b4' }}>
          <InputGroup className="mb-3 d-flex flex-row-reverse" ><i>{new Date(regtemp.criado.seconds * 1000).toLocaleString()}</i></InputGroup>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Estado</InputGroup.Text>
              <select class="form-select"
                  type="text"
                  id="estado"
                  required
                  value={regtemp.estado}
                  onChange={alteracaoManual}
                  name="estado" >
                <option value="Aberto">Aberto</option>
                <option value="Pago">Pago</option>
                </select>
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Id_secao</InputGroup.Text>
              <FormControl
                type="text"
                id="id_secao"
                required
                value={regtemp.id_secao}
                onChange={alteracaoManual}
                name="id_secao"
                disabled/>
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Nomesecao</InputGroup.Text>
              <FormControl
                type="text"
                id="nomesecao"
                required
                value={regtemp.nomesecao}
                onChange={alteracaoManual}
                name="nomesecao" 
                disabled/>
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Txid</InputGroup.Text>
              <FormControl
                type="text"
                id="txid"
                required
                value={regtemp.txid}
                onChange={alteracaoManual}
                name="txid" />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Uid</InputGroup.Text>
              <FormControl
                type="text"
                id="uid"
                required
                value={regtemp.uid}
                onChange={alteracaoManual}
                name="uid"
                disabled/>
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Validade</InputGroup.Text>
              <FormControl
                type="datetime-local"
                id="validade"
                required
                value={horalocal.hora}
                onChange={alteracaoManual}
                name="validade" />
            </InputGroup>
          </div>
          <div className="form-group">
            <InputGroup className="mb-3">
              <InputGroup.Text>Valor</InputGroup.Text>
              <FormControl
                type="text"
                id="valor"
                required
                value={regtemp.valor}
                onChange={alteracaoManual}
                name="valor" />
            </InputGroup>
          </div>
        </Paper>
        <button onClick={gravaRegistro} className="btn btn-success btn-sm">
          Atualizar
        </button>
      </div>
    </div>
  );
};

export default function IntegrationNotistack(registro) {
  return (
    <SnackbarProvider maxSnack={3}>
      <Edit registro={registro} />
    </SnackbarProvider>
  );
}