import React from "react";
import Nave from "./Nav";
import Footer from "./Footer";
import Listas from "./Funcoes/Listas"
import { useAuth } from "../contexts/AuthContext";

export default function Painel() {

  const { currentUser } = useAuth();

  return (
    <div>
      <Nave />
      <div className="container mt-3">
        <div className="row">
          <div className="col-xl-12">
            <Listas usuario = {currentUser}/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
