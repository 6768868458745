import React, { useState } from "react";
import { InputGroup, FormControl } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { SnackbarProvider, useSnackbar } from "notistack";
import DBService from "./dbservice";
import { useAuth } from "../../../contexts/AuthContext"

const Add = () => {

  const { currentUser } = useAuth();

  const estadoInicial = {
    uid: currentUser.uid,
    criado: "",
    apagar: "",
    nome: "",
    descricao: "",
    fluxograma: "",
    comandos: "",
    funcoes: "",
    local: "",
    linguagem: "",
    observacoes: "",
    porta: "",
  };

  const gravaRegistro = () => {
    var data = {
      uid: regtemp.uid,
      criado: new Date(),
      apagar: regtemp.apagar,
      nome: regtemp.nome,
      descricao: regtemp.descricao,
      fluxograma: regtemp.fluxograma,
      comandos: regtemp.comandos,
      funcoes: regtemp.funcoes,
      local: regtemp.local,
      linguagem: regtemp.linguagem,
      observacoes: regtemp.observacoes,
      porta: regtemp.porta,
    };
    DBService.criaRegistro(data)
      .then(() => {
        setEnviado(true);
        outro();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const { enqueueSnackbar } = useSnackbar();

  const outro = () => {
    handleClick("Seus dados foram gravados", "success");
  };

  const handleClick = (dados, variant) => {
    enqueueSnackbar(dados, { variant });
  };

  const [regtemp, setRegtemp] = useState(estadoInicial);
  const [enviado, setEnviado] = useState(false); //altera tela depois de enviar

  const alteracaoManual = event => {
    const { name, value } = event.target;
    setRegtemp({ ...regtemp, [name]: value });
  };

  const novoRegistro = () => {
    setRegtemp(estadoInicial);
    setEnviado(false);
  };

  return (
    <div className="submit-form">
      {enviado ? (
        <div>
          <h4>Seus dados foram gravados!</h4>
          <button className="btn btn-success btn-sm" onClick={novoRegistro}>
            Adicionar
          </button>
        </div>
      ) : (
        <div>
          <Paper style={{ maxHeight: 600, overflow: 'auto', backgroundColor: '#1e2129', color: '#98a0b4' }}>

            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Nome</InputGroup.Text>
                <FormControl
                  type="text"
                  id="nome"
                  required
                  value={regtemp.nome}
                  onChange={alteracaoManual}
                  name="nome" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Descricao</InputGroup.Text>
                <FormControl
                  as="textarea"
                  placeholder="Insira a descrição aqui..."
                  style={{ height: '100px' }}
                  id="descricao"
                  required
                  value={regtemp.descricao}
                  onChange={alteracaoManual}
                  name="descricao" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Fluxograma</InputGroup.Text>
                <FormControl
                  as="textarea"
                  placeholder="Insira a descrição aqui..."
                  style={{ height: '200px' }}
                  id="fluxograma"
                  required
                  value={regtemp.fluxograma}
                  onChange={alteracaoManual}
                  name="fluxograma" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Comandos</InputGroup.Text>
                <FormControl
                  as="textarea"
                  placeholder="Insira a descrição aqui..."
                  style={{ height: '200px' }}
                  id="comandos"
                  required
                  value={regtemp.comandos}
                  onChange={alteracaoManual}
                  name="comandos" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Funcoes</InputGroup.Text>
                <FormControl
                  as="textarea"
                  placeholder="Insira a descrição aqui..."
                  style={{ height: '200px' }}
                  id="funcoes"
                  required
                  value={regtemp.funcoes}
                  onChange={alteracaoManual}
                  name="funcoes" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Local</InputGroup.Text>
                <FormControl
                  type="text"
                  id="local"
                  required
                  value={regtemp.local}
                  onChange={alteracaoManual}
                  name="local" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Linguagem</InputGroup.Text>
                <FormControl
                  type="text"
                  id="linguagem"
                  required
                  value={regtemp.linguagem}
                  onChange={alteracaoManual}
                  name="linguagem" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Observacoes</InputGroup.Text>
                <FormControl
                  as="textarea"
                  placeholder="Insira a descrição aqui..."
                  style={{ height: '100px' }}
                  id="observacoes"
                  required
                  value={regtemp.observacoes}
                  onChange={alteracaoManual}
                  name="observacoes" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Porta</InputGroup.Text>
                <FormControl
                  type="text"
                  id="porta"
                  required
                  value={regtemp.porta}
                  onChange={alteracaoManual}
                  name="porta" />
              </InputGroup>
            </div>
          </Paper>
          <button onClick={gravaRegistro} className="btn btn-success btn-sm">
            Enviar
          </button>
        </div>
      )}
    </div>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Add />
    </SnackbarProvider>
  );
}