import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { db } from "../../../firebase";
import { doc, getDoc, setDoc } from 'firebase/firestore';
import Snackbar from '@material-ui/core/Snackbar';
import InputMask from 'react-input-mask';

const HorarioForm = (id) => {

    const sessao = id.sessao
    const [horario, setHorario] = useState({
        'segunda-feira': { inicio: '', fim: '' },
        'terça-feira': { inicio: '', fim: '' },
        'quarta-feira': { inicio: '', fim: '' },
        'quinta-feira': { inicio: '', fim: '' },
        'sexta-feira': { inicio: '', fim: '' },
        'sábado': { inicio: '', fim: '' },
        'domingo': { inicio: '', fim: '' },
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [formEnabled, setFormEnabled] = useState(false);
    const [registroHabilitado, setRegistroHabilitado] = useState(false);

    useEffect(() => {
        const fetchHorario = async () => {
            try {
                const docRef = doc(db, 'horarios', sessao);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setHorario(data);
                    setFormEnabled(data.habilitado);
                    setRegistroHabilitado(data.habilitado);
                } else {
                    const newHorario = {
                        'segunda-feira': { inicio: '00:00', fim: '00:00' },
                        'terça-feira': { inicio: '00:00', fim: '00:00' },
                        'quarta-feira': { inicio: '00:00', fim: '00:00' },
                        'quinta-feira': { inicio: '00:00', fim: '00:00' },
                        'sexta-feira': { inicio: '00:00', fim: '00:00' },
                        'sábado': { inicio: '00:00', fim: '00:00' },
                        'domingo': { inicio: '00:00', fim: '00:00' },
                        habilitado: false,
                    };

                    await setDoc(docRef, newHorario);
                    setHorario(newHorario);
                    setFormEnabled(false);
                    setRegistroHabilitado(false);
                }
            } catch (error) {
                console.error('Erro ao carregar os dados:', error);
            }
        };

        fetchHorario();
    }, []);

    const handleChange = (event, diaSemana, campo) => {
        const { value } = event.target;
        setHorario(prevHorario => ({
            ...prevHorario,
            [diaSemana]: {
                ...prevHorario[diaSemana],
                [campo]: value,
            },
        }));
    };

    const handleSubmit = async event => {
        event.preventDefault();
        try {
            const data = {
                ...horario,
                habilitado: registroHabilitado,
            };

            if (!registroHabilitado) {
                for (const diaSemana in data) {
                    data[diaSemana].inicio = '00:00';
                    data[diaSemana].fim = '00:00';
                }
            }

            const docRef = doc(db, 'horarios', sessao);
            await setDoc(docRef, data);
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Erro ao gravar os dados:', error);
        }
    };

    const handleToggleForm = () => {
        setFormEnabled(prevEnabled => !prevEnabled);
        setRegistroHabilitado(prevHabilitado => {
            const novoHabilitado = !prevHabilitado;
            const data = {
                ...horario,
                habilitado: novoHabilitado,
            };
            const docRef = doc(db, 'horarios', sessao);
            setDoc(docRef, data)
            return novoHabilitado;
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const diasDaSemana = [
        'segunda-feira',
        'terça-feira',
        'quarta-feira',
        'quinta-feira',
        'sexta-feira',
        'sábado',
        'domingo',
    ];

    const ordenarDiasDaSemana = dias => {
        const ordenacaoDias = {
            'segunda-feira': 1,
            'terça-feira': 2,
            'quarta-feira': 3,
            'quinta-feira': 4,
            'sexta-feira': 5,
            'sábado': 6,
            'domingo': 7,
        };

        return dias.sort((a, b) => ordenacaoDias[a] - ordenacaoDias[b]);
    };

    const diasDaSemanaOrdenados = ordenarDiasDaSemana(diasDaSemana);

    return (
        <div>
            <Alert variant={formEnabled ? 'warning' : 'info'}>
                {formEnabled
                    ? 'O atendimento virtual está disponível apenas fora do horário comercial. Por favor, preencha o horário de funcionamento, os campos da esquerda representam o horário de início e os campos da direita representam o horário de término.'
                    : 'O atendimento virtual está disponível 24 horas por dia. Se desejar, você pode habilitar a opção para que ele funcione somente fora do horário comercial.'}
            </Alert>

            <Button variant="secondary" onClick={handleToggleForm}>
                {formEnabled ? 'Desabilitar' : 'Habilitar'}
            </Button>

            {formEnabled && (
                <Form onSubmit={handleSubmit}>
                    {diasDaSemanaOrdenados.map(diaSemana => (
                        <Row key={diaSemana}>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        {diaSemana.charAt(0).toUpperCase() + diaSemana.slice(1)}:
                                    </Form.Label>
                                    <Row>
                                        <Col>
                                            <InputMask
                                                mask="99:99"
                                                placeholder="Inicio"
                                                value={horario[diaSemana].inicio}
                                                onChange={event => handleChange(event, diaSemana, 'inicio')}
                                                disabled={!formEnabled}
                                            >
                                                {(inputProps) => <Form.Control {...inputProps} />}
                                            </InputMask>
                                        </Col>
                                        <Col>
                                            <InputMask
                                                mask="99:99"
                                                placeholder="Fim"
                                                value={horario[diaSemana].fim}
                                                onChange={event => handleChange(event, diaSemana, 'fim')}
                                                disabled={!formEnabled}
                                            >
                                                {(inputProps) => <Form.Control {...inputProps} />}
                                            </InputMask>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                    ))}
                    <Button variant="primary" type="submit" disabled={!formEnabled}>
                        Salvar
                    </Button>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                        message="Dados gravados com sucesso!"
                    />
                </Form>
            )}
        </div>
    );
};

export default HorarioForm;
