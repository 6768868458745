import { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import {db} from "../../../firebase"

const TesteChat = () => {
  const [messages, setMessages] = useState([
    { text: 'Bem-vindo ao nosso Chat da Agt Eletronic, eu sou Ariel e estarei disponível para te ajudar.', sender: 'bot' },
    { text: 'Você pode iniciar com estas perguntas:', sender: 'bot' },
    { text: 'Ou perguntar o que você quiser no campo abaixo.', sender: 'bot' },
  ]);
  const [newMessage, setNewMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [prompt, setPrompt] = useState(''); // Inicialize prompt como uma string vazia

  const scrollToBottom = () => {
    const chatBox = document.getElementById('chat-box');
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const fetchPromptFromFirestore = async () => {
      const docRef = doc(db, 'infoAtendente', 'server'); // Referência ao documento 'server' na coleção 'infoAtendente'
      try {
        const docSnap = await getDoc(docRef); // Obtenha o documento do Firestore
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data && data.resultado) {
            // Defina a variável 'prompt' com base nos dados do Firestore
            setPrompt(data.resultado);
          }
        } else {
          console.log('Documento não encontrado.');
        }
      } catch (error) {
        console.error('Erro ao buscar dados do Firestore:', error);
      }
    };

    fetchPromptFromFirestore(); // Chame a função para buscar os dados do Firestore
  }, []); // Certifique-se de que este useEffect seja executado apenas uma vez

  const handleSubmitMessage = async (message) => {
    if (message !== '') {
      const userMessage = { text: message, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setNewMessage('');

      const requestData = {
        message: message,
        informacoes: prompt
      };
      
      try {
        const response = await axios.post('https://suporte.agteletronic.com.br/chathistorico', requestData);
        const respostaDoServidor = response.data.response.content;
        const botMessage = { text: respostaDoServidor, sender: 'bot' };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } catch (error) {
        console.error('Erro ao obter resposta da API:', error);
      }
    } else {
      setShowAlert(true);
    }
  };

  const handleMessageSubmit = async (e) => {
    e.preventDefault(); // Evita o envio padrão do formulário

    if (newMessage !== '') {
      const userMessage = { text: newMessage, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setNewMessage('');

      const requestData = {
        message: newMessage,
        informacoes: prompt
      };

      try {
        const response = await axios.post('https://suporte.agteletronic.com.br/chathistorico', requestData);
        const respostaDoServidor = response.data.response.content;
        const botMessage = { text: respostaDoServidor, sender: 'bot' };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } catch (error) {
        console.error('Erro ao obter resposta da API:', error);
      }
    } else {
      setShowAlert(true);
    }
  };

  const handleQuickButtonClick = (buttonText) => {
    handleSubmitMessage(buttonText);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleMessageSubmit();
    }
  };

  return (
    <Container className="mt-4 teste-chat">
      <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        Digite uma mensagem antes de enviar.
      </Alert>
      <Alert variant="success" show={isCopied} onClose={() => setIsCopied(false)} dismissible>
        Conversa copiada com sucesso!
      </Alert>

      <div className="chat-box" id="chat-box">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.sender === 'user' ? 'user' : 'bot'}`}
            style={{ textAlign: message.sender === 'user' ? 'right' : 'inherit' }}
          >
            {message.sender === 'bot' ? (
              message.link ? (
                <div>
                  <a href={message.link} target="_blank" rel="noopener noreferrer">
                    <img src={message.image} alt="Imagem" style={{ maxWidth: '100%', height: 'auto' }} />
                  </a>
                </div>
              ) : (
                <div>
                  {message.text}
                  {index === 1 && <QuickButtons onButtonClick={handleQuickButtonClick} />}
                </div>
              )
            ) : (
              message.text
            )}
          </div>
        ))}
      </div>
      <Form onSubmit={handleMessageSubmit}>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Digite sua mensagem..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </Form.Group>
      </Form>
    </Container>
  );
};


const QuickButtons = ({ onButtonClick }) => {
  const buttons = ['Como funciona o chatbot?'];

  return (
    <div className="quick-buttons">
      {buttons.map((buttonText, index) => (
        <Button key={index} onClick={() => onButtonClick(buttonText)}>
          {buttonText}
        </Button>
      ))}
    </div>
  );
};

export default TesteChat;
