import React, { useState } from "react";
import { InputGroup, FormControl } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import { SnackbarProvider, useSnackbar } from "notistack";
import DBService from "./dbservice";

const Add = () => {
  const estadoInicial = {
    uid: "",
    criado: "",
    apagar: "",
    assunto: "",
    intencao: "",
    resposta: "",
    tipo: "",
    categoria: "",
  };

  const gravaRegistro = () => {
    var data = {
      criado: new Date(),
      assunto: regtemp.assunto,
      intencao: regtemp.intencao,
      pergunta: regtemp.pergunta,
      resposta: regtemp.resposta,
      tipo: regtemp.tipo,
      categoria: regtemp.categoria,
    };
    DBService.criaRegistro(data)
    setEnviado(true);
    outro();
  };

  const { enqueueSnackbar } = useSnackbar();

  const outro = () => {
    handleClick("Seus dados foram gravados", "success");
  };

  const handleClick = (dados, variant) => {
    enqueueSnackbar(dados, { variant });
  };

  const [regtemp, setRegtemp] = useState(estadoInicial);
  const [enviado, setEnviado] = useState(false); //altera tela depois de enviar

  const alteracaoManual = event => {
    const { name, value } = event.target;
    setRegtemp({ ...regtemp, [name]: value });
  };

  const novoRegistro = () => {
    setRegtemp(estadoInicial);
    setEnviado(false);
  };

  return (
    <div className="submit-form">
      {enviado ? (
        <div>
          <h4>Seus dados foram gravados!</h4>
          <button className="btn btn-success btn-sm" onClick={novoRegistro}>
            Adicionar
          </button>
        </div>
      ) : (
        <div>
          <Paper style={{ maxHeight: 600, overflow: 'auto', backgroundColor: '#1e2129', color: '#98a0b4' }}>

            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Assunto</InputGroup.Text>
                <FormControl
                  type="text"
                  placeholder="Descreva em poucas palavras."
                  id="assunto"
                  required
                  value={regtemp.assunto}
                  onChange={alteracaoManual}
                  name="assunto" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Intencao</InputGroup.Text>
                <FormControl
                  as="textarea"
                  placeholder="Descreva a intenção que vai aparecer para usúario ler."
                  style={{ height: '100px' }}
                  id="intencao"
                  required
                  value={regtemp.intencao}
                  onChange={alteracaoManual}
                  name="intencao" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Pergunta</InputGroup.Text>
                <FormControl
                  type="text"
                  id="pergunta"
                  placeholder="Pergunta que vai ser registrada no dialogflow."
                  required
                  value={regtemp.pergunta}
                  onChange={alteracaoManual}
                  name="pergunta" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Resposta</InputGroup.Text>
                <FormControl
                  as="textarea"
                  placeholder="Escreva a resposta pré configurada que vai aparecer para o usuário editar."
                  style={{ height: '100px' }}
                  id="resposta"
                  required
                  value={regtemp.resposta}
                  onChange={alteracaoManual}
                  name="resposta" />
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Tipo</InputGroup.Text>
                <select class="form-select"
                  type="text"
                  placeholder="Pré, é a resposta que vai ser gerado automaticamente quando usúario criar a sessão."
                  id="tipo"
                  required
                  value={regtemp.tipo}
                  onChange={alteracaoManual}
                  name="tipo" >
                <option selected></option>
                <option value="pré">Pré - gerado automaticamente</option>
                </select>
              </InputGroup>
            </div>
            <div className="form-group">
              <InputGroup className="mb-3">
                <InputGroup.Text>Categoria</InputGroup.Text>
                <FormControl
                  type="text"
                  id="categoria"
                  required
                  value={regtemp.categoria}
                  onChange={alteracaoManual}
                  name="categoria" />
              </InputGroup>
            </div>
          </Paper>
          <button onClick={gravaRegistro} className="btn btn-success btn-sm">
            Enviar
          </button>
        </div>
      )}
    </div>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Add />
    </SnackbarProvider>
  );
}