import React, { useState, useEffect } from "react";
import { collection, onSnapshot } from 'firebase/firestore';
import Paper from "@material-ui/core/Paper";
import { Table } from 'react-bootstrap';
import Tooltip from "@material-ui/core/Tooltip";
import 'moment/locale/pt-br';
import { db } from "../../../firebase";
import DBService from "./dbservice";
import Modaledit from "./modalEdit";


const ListadeResgistrosTabela = (valorTotal) => {

  const [registros, setRegistros] = useState([]);
  const [somaValores, setSomaValores] = useState(0);
  const [anoFiltrado, setAnoFiltrado] = useState(0); // Defina o ano desejado aqui

  const registrosFiltrados = registros.filter((registro) => {
    if (anoFiltrado === 0) {
      return true; // Mostrar todos os registros
    }
    const data = new Date(registro.data);
    return data.getFullYear() === anoFiltrado;
  });

  const registrosOrdenados = registrosFiltrados.slice().sort((a, b) => {
    // Convertendo as datas para objetos Date para a comparação
    const dataA = new Date(a.data);
    const dataB = new Date(b.data);
    return dataB - dataA;
  });

  useEffect(() => {
    const unsub = onSnapshot(collection(db, 'dividendos'), (snapshot) => {
      const dadosRegistros = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setRegistros(dadosRegistros);
    });

    return unsub;
  }, []);

  const somaValoresFiltrados = registrosFiltrados.reduce((total, registro) => {
    return total + parseFloat(registro.valor || 0);
  }, 0);


  const deletaRegistro = (registro) => {
    if (window.confirm("Você vai mesmo deletar isso?")) {
      DBService.remove(registro)
        .then(() => {
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div>

      {registros.length === 0 ? (
        <div>
          <div class="alert alert-warning" role="alert">
            Ainda não temos nenhum registro cadastrado, clique em "+" para cadastrar.
          </div>
        </div>
      ) : (
        <div>
          <select onChange={(e) => setAnoFiltrado(parseInt(e.target.value))}>
            <option value={0}>Mostrar Todos</option>
            <option value={2026}>2026</option>
            <option value={2025}>2025</option>
            <option value={2024}>2024</option>
            <option value={2023}>2023</option>
            <option value={2022}>2022</option>
            <option value={2021}>2021</option>
            <option value={2020}>2020</option>
            <option value={2019}>2019</option>
            <option value={2018}>2018</option>
            <option value={2017}>2017</option>
            {/* Adicione mais opções para outros anos, se necessário */}
          </select>
          <div>
            <p>Total dos Valores: {somaValoresFiltrados.toFixed(2)}</p>
            <p>Retorno em porcentagem: {(somaValoresFiltrados.toFixed(2) * 100 / valorTotal.valorTotal.valorTotal).toFixed(2)}</p>
          </div>
          <div className="card mb-4">
            <div style={{ width: '100%' }}>
              <ul className="list-group">
                <Paper style={{ maxHeight: 400, overflow: 'auto' }}>
                  <Table striped bordered hover variant="dark">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Valor</th>
                        <th>Data</th>
                        <th>Comandos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {registrosOrdenados.map((registro) => (
                        <tr key={registro.id}>
                          <td>{registro.nome}</td>
                          <td>{registro.valor}</td>
                          <td>{registro.data}</td>
                          <td>
                            <Tooltip title="Deletar">
                              <button onClick={() => deletaRegistro(registro.id)} className="btn btn-danger btn-sm">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </button>
                            </Tooltip>
                            <Modaledit registro={registro} />
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </Table>
                </Paper>
              </ul>
            </div>
          </div>
         
        </div>
      )}

    </div>
  );
};

export default ListadeResgistrosTabela;
