import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import Add from "./addregistro";

export default function AddModal() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Tooltip title="Adicionar novo registro">
        <button onClick={handleShow} className="btn btn-primary btn-sm">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </button>
      </Tooltip>
      <Modal
        size="lg"
        centered
        className="special_modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Adicionar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Add />
        </Modal.Body>
        <Modal.Footer>
        <Tooltip title="Fechar">
          <Button className="btn-secondary btn-sm" onClick={handleClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}

