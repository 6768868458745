import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import Nave from "./Nav";
import Footer from "./Footer";
import Container from "@material-ui/core/Container";

export default function UpdateLogin() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, updatePasswords, updateEmails } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();


  function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("As senhas não coincidem");
    }

    const promises = [];
    setLoading(true);
    setError("");

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmails(emailRef.current.value));
    }
    if (passwordRef.current.value) {
      promises.push(updatePasswords(passwordRef.current.value));
    }

    Promise.all(promises)
      .then(() => {
        history.push("/");
        setError("Atualizado com sucesso!");
      })
      .catch(() => {
        setError("Falha ao atualizar a conta");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Nave />
      <Container component="main" maxWidth="xs">
      <br/><br/>
        <div className="container mt-3">
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Dados de acesso</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    ref={emailRef}
                    required
                    defaultValue={currentUser.email}
                  />
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    ref={passwordRef}
                    placeholder="Deixe em branco para manter o mesmo"
                  />
                </Form.Group>
                <Form.Group id="password-confirm">
                  <Form.Label>Confirmação de senha</Form.Label>
                  <Form.Control
                    type="password"
                    ref={passwordConfirmRef}
                    placeholder="Deixe em branco para manter o mesmo"
                  />
                </Form.Group>
                <br></br>
                <Button disabled={loading} className="w-100" t1 type="submit">
                  Atualizar
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
        <div className="w-100 text-center mt-2">
          <Link to="/">Voltar</Link>
        </div>
        <br/><br/>
      </Container>
      <Footer />
    </>
  );
}
