import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { Button, Modal, Image } from "react-bootstrap";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { db } from "../firebase";
import { onSnapshot, doc } from "firebase/firestore";

export default function Nave() {
  const [documento, setDocumento] = useState({
    nome: "",
    telefone: "",
    endereço: "",
    imagem: "",
    uid: "",
    criado: new Date(),
    dataevento: "",
    servidor: "",
    estado: "",
  });

  const { currentUser } = useAuth();

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "usuarios", currentUser.uid), (doc) => {
      setDocumento({ ...doc.data(), id: doc.id });
    });
    return unsub;
  }, []);

  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/">
        <Image src={"image/logo.png"} fluid style={{ maxWidth: "150px", maxHeight: "50px" }} />
        </Navbar.Brand>
        <Nav className="mr-left">
          <Nav className="justify-content-end" activeKey="/home">
            <Nav.Item></Nav.Item>
          </Nav>
          <NavDropdown title={documento.nome} id="basic-nav-dropdown">
            <NavDropdown.Item>
              <Link to={"/update-login"} className="link">
                <i class="fa fa-lock" aria-hidden="true"></i> Dados de acesso
              </Link>
            </NavDropdown.Item>
            <Confirmacao documento={documento} />
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
}

function Confirmacao({ documento }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [setError] = useState("");
  const { logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <>
      <NavDropdown.Item onClick={handleShow}>
        <i class="fa fa-window-close" aria-hidden="true"></i> Sair
      </NavDropdown.Item>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sair</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>{documento.nome}</strong>, você está saindo do Aplicativo, deseja confirmar?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary btn-sm" onClick={handleClose}>
            Depois
          </Button>
          <Button variant="primary btn-sm" onClick={handleLogout}>
            Sair
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
